import React from 'react';
import { Tabs, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import StatisticsPage1 from './StatisticsPage1';  // Перша сторінка зі статистикою
import StatisticsPage2 from './StatisticsPage2';  // Друга сторінка зі статистикою

const { TabPane } = Tabs;
const { Content } = Layout;

const StatisticsPage = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <Content style={{ padding: '12px' }}>
                <Tabs 
                    defaultActiveKey="1"
                    size='small'
                >
                    <TabPane tab={t('Status of requests')} key="1">
                        <StatisticsPage1 />
                    </TabPane>
                    <TabPane tab={t('Details')} key="2">
                        <StatisticsPage2 />
                    </TabPane>
                </Tabs>
            </Content>
        </Layout>
    );
};

export default StatisticsPage;