import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Menu, Space, Select, Avatar, Button, Popover, Typography, Radio, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { MainRouter } from '../MainRouter';
import { GB, UA } from 'country-flag-icons/react/3x2'
import { loadState } from '../../utils/localStorage';
import { useLanguage } from '../../utils/hooks/useLanguage';
import { useGetUserInfoQuery } from '../../utils/api/userApi';

import { ExceptionOutlined, PlayCircleOutlined, UserOutlined, AreaChartOutlined } from '@ant-design/icons';

// import dayjs from 'dayjs';
import enUS from 'antd/locale/en_US';
import ukUA from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';
import { ConfigProvider } from 'antd';

import './MainLayout.css';
import LogoutButton from './LogoutButton';

const { Header, Content, Footer } = Layout;
const { Title } = Typography;

const UserMenuContent = ({username}) => {
  const auth = loadState('auth');
  const { t, i18n } = useTranslation();
  const [renderKey, setRenderKey] = useState(Date.now());
  const [language, updateLanguage] = useLanguage();

  useEffect(() => {
    const storedLanguage = auth?.language;
    if (storedLanguage) {
      updateLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    updateLanguage(lang);
    setRenderKey(Date.now());
  };

  return (
    <ConfigProvider
    theme={{ token: {
      colorPrimary: '#01335d',
      },}}
      >
    <Space direction="vertical">
    <Radio.Group buttonStyle="solid" onChange={(v)=>{changeLanguage(v.target.value)}} defaultValue={auth?.language} optionType="button">
      <Tooltip className="main-layout-language-radio" title={t('English')}><Radio.Button  value="en"><GB className="flag-icon"/></Radio.Button></Tooltip>
      <Tooltip className="main-layout-language-radio" title={t('Українська')}><Radio.Button value="ua"><UA className="flag-icon"/></Radio.Button></Tooltip>
    </Radio.Group>
    <LogoutButton />
</Space>
</ConfigProvider>
  )
};

const tempLogoParams = {
    bgColor: "#5394EA",
    logo: `${process.env.PUBLIC_URL}/unicef.png`
}

const MainLayout = () => {

  const {data: userInfo, isLoading, isFetching, isSuccess} = useGetUserInfoQuery();

  const auth = loadState('auth');
  const permissions = useSelector((state) => state.auth.permissions);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);
  const navigate = useNavigate();
  const [renderKey, setRenderKey] = useState(Date.now());
  const [language, updateLanguage] = useLanguage();
  // const [menuitemsFiltered, setMenuItemsFiltered] = useState([]);


  const onClick = (e) => {
    setCurrent(e.key);
    navigate(e.key);
  };

  const menuitems = [
    { key: '/tickets', label: t('Tickets'), icon: <ExceptionOutlined />, permission: "view_ticket"},
    { key: '/statistics', label: t('Statistics'), icon: <AreaChartOutlined />, permission: "view_ticket"},
    { key: '/users', label: t('Users'), icon: <UserOutlined /> , permission: "manage_users"},
    { key: '/audiorecords', label: t('Audiorecords'), icon: <PlayCircleOutlined />, permission: "view_audiorecord"},
  ];

  const menuitemsFiltered = menuitems.filter(item => permissions.includes(item.permission));

  useEffect(() => {
    const storedLanguage = auth?.language;
    if (storedLanguage) {
      updateLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  // useEffect(() => {
  //   if (userInfo) {
  //     if (!isLoading && !isFetching && isSuccess) {
  //       setMenuItemsFiltered(menuitems.filter(item => permissions.includes(item.permission)));
  //     }
  //   }
  // }, [userInfo, isLoading, isFetching, isSuccess]);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  // const changeLanguage = (lang) => {
  //   i18n.changeLanguage(lang);
  //   updateLanguage(lang);
  //   setRenderKey(Date.now());
  // };

  // const menuitemsFiltered = menuitems.filter(item => permissions.includes(item.permission))

  return (
    <>
      <ConfigProvider locale={language === 'en' ? enUS : ukUA} localeAntd={language === 'en' ? enUS : ukUA}>
      <Layout key={renderKey} className="main-layout-layout">
        <Header className="main-layout-header">
          <Link to="/tickets">
            <img src={`${process.env.PUBLIC_URL}/adelina_favicon-150x150.png`} className="logo" />
          </Link>
          <Menu
            onClick={onClick}
            theme="dark"
            mode="horizontal"
            className="main-layout-menu"
            selectedKeys={[current]}
            items={menuitemsFiltered}
          />
          <Space direction="horizontal">
            <Popover 
              style={{alignItems: 'center'}}
              arrow={false}
              content={<UserMenuContent username={auth?.username} />} 
              trigger="hover" 
              placement="bottomRight">
                <Space direction="horizontal">
                  <UserOutlined style={{cursor: "pointer", color:"white"}}/>
                  <Link style={{color:"white"}}>{auth?.username}</Link>
                  <Avatar 
                    className="main-layout-avatar" 
                    style={{cursor: "pointer", backgroundColor: "white"}} 
                    size="large"
                    shape="square" 
                    src={tempLogoParams?.logo}
                    >
                      {auth?.username?.[0]?.toUpperCase()}
                    </Avatar>
                </Space>
            </Popover>
          </Space>
        </Header>
        <Content className="main-layout-content">
          <MainRouter />
        </Content>
        <Footer></Footer>
      </Layout>
      </ConfigProvider>
    </>
  );
};

export default MainLayout;
