import React, { useEffect, useMemo, useState } from 'react';
import { Layout, Row, Col, Card, Tree } from 'antd';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import './StatisticsPage.css'; 
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';

import { useGetStatisticsQuery } from '../../utils/api/ticketsApi';

const { Content } = Layout;

const StatisticsPage1 = () => {
    const { t, i18n } = useTranslation();
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    
    const startYear = 2024;  // Початковий рік
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();  // Поточний місяць (0 - січень, 11 - грудень)
    const [selectedMonth, setSelectedMonth] = useState(`${currentYear}-${currentMonth}`);
    
    // Правильний розрахунок year і monthIndex на основі selectedMonth
    const [year, monthIndex] = selectedMonth.split('-');  // Рік і місяць витягуються з selectedMonth
    const date_start = moment().year(year).month(monthIndex).startOf('month').format('YYYY-MM-DD');
    const date_end = moment().year(year).month(monthIndex).endOf('month').format('YYYY-MM-DD');

    // Оновлення запиту з новими параметрами
    const { data, error, isLoading } = useGetStatisticsQuery({ date_start, date_end });

    const currentLocale = i18n.language;

    useEffect(() => {
        if (data?.data && data.data.length > 0) {
            // Визначаємо унікальні статуси на основі даних
            const uniqueStatuses = [...new Set(data.data.map(row => row.status[currentLocale === 'ua' ? 'name' : 'name_en']))];
            setSelectedStatuses(uniqueStatuses);
        }
    }, [data, currentLocale]);

    // Динамічне формування фільтру місяців з перекладом
    useEffect(() => {
        moment.locale(currentLocale); // Встановлення локалі для moment
    }, [currentLocale]);
    
    const months = useMemo(() => {
        return currentLocale === 'ua'
            ? ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"]
            : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }, [currentLocale]);
    
    const currentMonthIndex = moment().month();

    const monthTreeData = useMemo(() => {
        const years = [];
        for (let year = startYear; year <= currentYear; year++) {
            years.push({
                title: `${year}`,
                key: `${year}`,
                children: months
                    .slice(0, year === currentYear ? currentMonth + 1 : 12)  // Якщо рік поточний, місяці тільки до поточного включно
                    .map((month, index) => ({
                        title: month,
                        key: `${year}-${index}`,  // Ключ тепер включає рік і місяць
                    })),
            });
        }
        return years;
    }, [months, currentMonth, currentYear]);

    const defaultExpandedKeys = useMemo(() => [`${currentYear}`], [currentYear]);

    const onMonthSelect = (selectedKeys) => {
        if (selectedKeys.length > 0) {
            setSelectedMonth(selectedKeys[0]);
        }
    };

    const totalSums = useMemo(() => {
        if (!data?.data) return {};
    
        const sectors = [...new Set(data.data.map(row => row.sector[currentLocale === 'ua' ? 'name' : 'name_en']))];
    
        const totals = {};
        sectors.forEach(sector => {
            totals[sector] = data.data.reduce((sum, row) => sum + (row.sector[currentLocale === 'ua' ? 'name' : 'name_en'] === sector ? row.count : 0), 0);
        });
    
        totals[t('Total')] = Object.values(totals).reduce((sum, value) => sum + value, 0);
    
        return totals;
    }, [data, currentLocale, t]);

    const totalPercentages = useMemo(() => {
        if (!data?.data) return {};
        const totals = {};

        Object.keys(totalSums).forEach(key => {
            totals[key] = ((totalSums[key] / totalSums[t('Total')]) * 100).toFixed(1) + '%';
        });

        return totals;
    }, [totalSums, t]);

    const minMaxValues = useMemo(() => {
        if (!data?.data) return { min: 0, max: 100 };
    
        const values = Object.keys(totalPercentages)
            .map(key => parseFloat(totalPercentages[key]))
            .filter(value => value !== 100);  // Виключаємо 100%
    
        const min = Math.min(...values);
        const max = Math.max(...values);
    
        return { min, max };
    }, [totalPercentages]);

    const getGradientColor = (value, min, max) => {
        const ratio = (value - min) / (max - min);
        const red = Math.round(200 * (1 - ratio));
        const green = Math.round(50 + (105 * ratio)); // Від 150 до 255, щоб зелений був темнішим на максимальних значеннях
        return `rgba(${red}, ${green}, 0, 0.7)`;
    };

    const columns = useMemo(() => {
        if (!data?.data || data.data.length === 0) return [];
    
        const sectors = [...new Set(data.data.map(row => row.sector[currentLocale === 'ua' ? 'name' : 'name_en']))];
        const dynamicColumns = sectors.map(sector => ({
            header: sector,
            accessorKey: sector,
            cell: info => info.getValue(),
        }));
    
        return [
            { 
                header: t('Status'), 
                accessorKey: 'status',
                cell: info => <strong>{info.getValue()}</strong>,
                headerProps: { style: { width: '200px', backgroundColor: '#e0e0e0', fontWeight: 'bold', textAlign: 'center', color: '#333' } } 
            },
            ...dynamicColumns,
            { 
                header: t('Total'), 
                accessorKey: 'Total',
                cell: info => <strong>{info.getValue()}</strong>,
                headerProps: { style: { backgroundColor: '#e0e0e0', fontWeight: 'bold', textAlign: 'center', color: '#333' } }
            },
            { 
                header: '%', 
                accessorKey: '%',
                cell: info => {
                    const value = parseFloat(info.getValue());
                    if (value === 100) return null;  // Прибираємо 100% з відображення
                    const color = getGradientColor(value, minMaxValues.min, minMaxValues.max);
                    return <span style={{ backgroundColor: color, padding: '5px', borderRadius: '3px', display: 'block', textAlign: 'center', color: 'white' }}><strong>{info.getValue()}%</strong></span>;
                },
                headerProps: { style: { backgroundColor: '#e0e0e0', fontWeight: 'bold', textAlign: 'center', color: '#333' } }
            },
        ];
    }, [data, minMaxValues, t, currentLocale]);

    const tableData = useMemo(() => {
        if (!data?.data) return [];
    
        // Перетворюємо структуру даних для використання в таблиці
        const formattedData = selectedStatuses.map(status => {
            const filteredData = data.data.filter(row => row.status[currentLocale === 'ua' ? 'name' : 'name_en'] === status);
            const rowData = { 'status': status };
            let sum = 0;
    
            filteredData.forEach(item => {
                rowData[item.sector[currentLocale === 'ua' ? 'name' : 'name_en']] = item.count;
                sum += item.count;
            });
    
            rowData[t('Total')] = sum;
            // Обчислюємо відсоток для кожного сектора відносно загальної суми
            rowData['%'] = ((sum / totalSums[t('Total')]) * 100).toFixed(1);
    
            return rowData;
        });
    
        return formattedData;
    }, [data, selectedStatuses, totalSums, currentLocale, t]);

    // Дані для діаграми статусів
    const pieDataStatus = useMemo(() => {
        if (!data?.data) return [];
        return selectedStatuses.map(status => {
            const total = data.data.filter(row => row.status[currentLocale === 'ua' ? 'name' : 'name_en'] === status)
                                    .reduce((sum, row) => sum + row.count, 0);
            return { name: status, value: total };
        });
    }, [data, selectedStatuses, currentLocale]);

    // Дані для діаграми категорій
    const pieDataCategories = useMemo(() => {
        if (!data?.data) return [];
        const sectors = [...new Set(data.data.map(row => row.sector[currentLocale === 'ua' ? 'name' : 'name_en']))];

        return sectors.map(sector => {
            const total = data.data.reduce((sum, row) => sum + (row.sector[currentLocale === 'ua' ? 'name' : 'name_en'] === sector ? row.count : 0), 0);
            return { name: sector, value: total };
        });
    }, [data, currentLocale]);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF6666', '#AF19FF', '#19FF72', '#FF1972', '#1972FF'];

    const table = useReactTable({
        data: tableData,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    if (isLoading) {
        return <div>{t('loading')}</div>;
    }

    if (error) {
        return <div>{t('error')}: {error.message}</div>;
    }

    return (
        <Layout>
            <Content style={{ padding: '2px' }}>
                <Row gutter={16}>
                    <Col span={4}>
                        <Card>
                            <div>
                                <Tree
                                    defaultExpandAll={false}
                                    defaultExpandedKeys={defaultExpandedKeys} // Тільки поточний рік розгорнутий
                                    selectedKeys={[selectedMonth]}  // Поточний місяць вибраний за замовчуванням
                                    onSelect={onMonthSelect}
                                    treeData={monthTreeData}
                                />
                            </div>
                        </Card>
                    </Col>
                    <Col span={20}>
                        <table className="custom-table">
                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                <tr>
                                    <td><strong>{t('Total')}</strong></td>
                                    {Object.keys(totalSums).map(key => (
                                        <td key={key}><strong>{totalSums[key]}</strong></td>
                                    ))}
                                    <td></td> {/* Видаляємо значення "100%" */}
                                </tr>
                                <tr>
                                    <td><strong>{t('%')}</strong></td>
                                    {Object.keys(totalPercentages).map(key => {
                                        const value = parseFloat(totalPercentages[key]);
                                        if (value === 100) return <td key={key}></td>;  // Прибираємо 100% з відображення
                                        const color = getGradientColor(value, minMaxValues.min, minMaxValues.max);
                                        return (
                                            <td key={key} style={{ backgroundColor: color, textAlign: 'center', color: 'white' }}>
                                                <strong>{totalPercentages[key]}</strong>
                                            </td>
                                        );
                                    })}
                                    <td></td> {/* Видаляємо 100% з колонки "Сумарно %" */}
                                </tr>
                            </tbody>
                        </table>
                        <Row gutter={16} style={{ marginTop: '20px' }}>
                            <Col span={12}>
                                <Card title={t('Statuses')}>
                                    <ResponsiveContainer width="100%" height={300}>
                                        <PieChart>
                                            <Pie
                                                data={pieDataStatus}
                                                cx="50%"
                                                cy="50%"
                                                outerRadius={100}
                                                fill="#8884d8"
                                                dataKey="value"
                                                label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(2)}%`}
                                            >
                                                {pieDataStatus.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip formatter={(value) => `${value}`} />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card title={t('Sectors')}>
                                    <ResponsiveContainer width="100%" height={300}>
                                        <PieChart>
                                            <Pie
                                                data={pieDataCategories}
                                                cx="50%"
                                                cy="50%"
                                                outerRadius={100}
                                                fill="#8884d8"
                                                dataKey="value"
                                                label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(2)}%`}
                                            >
                                                {pieDataCategories.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip formatter={(value) => `${value}`} />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};

export default StatisticsPage1;