import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React from 'react';
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { isLoggedIn } from './app/utils/reducers/authReducer';
import { CheckStatusPage, ClientStartPage, FeedbackPage, LoginPage, MainLayout, TicketCreatePage } from './app/components';
import { MainRouter } from './app/components';
import { useRefreshTokenTimer } from './app/utils/hooks/useRefreshTokenTimer';


function App() {
  const location = useLocation();
  const loggedIn = useSelector(isLoggedIn);
  useRefreshTokenTimer();
  if(location.pathname === '/client_start_page') { return <MainRouter />}
  if(location.pathname.startsWith('/feedback')) { return <GoogleReCaptchaProvider reCaptchaKey={"6LfdNb8pAAAAALrrBSSh_xPZw2YNO8cf0LwnK7mr"}><MainRouter /></GoogleReCaptchaProvider>}
  if(location.pathname.startsWith('/start')) { return <MainRouter />} 
  if(location.pathname.startsWith('/create_ticket')) { return <GoogleReCaptchaProvider reCaptchaKey={"6LfdNb8pAAAAALrrBSSh_xPZw2YNO8cf0LwnK7mr"}><TicketCreatePage /></GoogleReCaptchaProvider>} 
  if(location.pathname.startsWith('/check_ticket_status')) { return <GoogleReCaptchaProvider reCaptchaKey={"6LfdNb8pAAAAALrrBSSh_xPZw2YNO8cf0LwnK7mr"}><CheckStatusPage /></GoogleReCaptchaProvider>} 
  return loggedIn ? <MainLayout /> : <GoogleReCaptchaProvider reCaptchaKey={"6LfdNb8pAAAAALrrBSSh_xPZw2YNO8cf0LwnK7mr"}><LoginPage /></GoogleReCaptchaProvider>;
}

export default App;
