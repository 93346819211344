import React, { useState, useEffect } from "react";
import { Table, Spin, Alert, Button, Tag, Tooltip, DatePicker, Input, Select, Space, Flex, Checkbox, Row } from "antd";
import { EditOutlined, SyncOutlined, IssuesCloseOutlined, PhoneOutlined, ClearOutlined, SecurityScanOutlined, MessageOutlined, SafetyCertificateOutlined } from "@ant-design/icons";
import TicketDetailsPage from "./TicketDetailsPage";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";

import { 
  useGetTicketsQuery, 
  useGetThemesQuery,
  useGetStatusesQuery,
  useGetTypesQuery,
  useGetCategoriesQuery,
  useGetSectorsQuery,
  useGetSourcesQuery,
  useGetManagersQuery,
  useGetRegionsQuery,
  useGetDistrictsQuery,
  useGetOtgsQuery,
  useGetOblastQuery,
  useGetRayonQuery,
  useGetHromadaQuery,

} from "../../utils/api/ticketsApi";
import { useGroup } from "../../utils/hooks/usePermission";
import "./TicketsPage.css";
import { loadState, saveState } from "../../utils/localStorage";

import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { render } from "@testing-library/react";


dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

const { RangePicker } = DatePicker;
const { Option } = Select;


const statusColors = [
  {color: "red", icon: <IssuesCloseOutlined />}, //new
  {color: "blue", icon: <SecurityScanOutlined />}, //in progress
  {color: "orange", icon: <MessageOutlined />}, //on hold
  {color: "grey", icon: <SyncOutlined />}, //rejected
  {color: "green", icon: <SafetyCertificateOutlined />} //closed
];


const TicketsPage = () => {
  const { t } = useTranslation();
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [ticketsCount, setTicketsCount] = useState();

  const [dateRange, setDateRange] = useState(null);
  const [debouncedPhoneNumber, setDebouncedPhoneNumber] = useState(null); // [phoneNumber, setPhoneNumber
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [status, setStatus] = useState(null);
  const [category, setCategory] = useState(null);
  const [isTest, setIsTest] = useState(0);
  const [type, setType] = useState(null);
  const [source, setSource] = useState(null);
  const [responsible, setResponsible] = useState(null);
  const [debouncedSearchText, setDebouncedSearchText] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [isFiltered, setIsfiltered] = useState(null);

  const [oblastId, setOblastId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data: statusesData } = useGetStatusesQuery();
  const { data: typesData } = useGetTypesQuery();
  const { data: categoriesData } = useGetCategoriesQuery();
  const { data: sourcesData } = useGetSourcesQuery();
  const { data: responsiblesData } = useGetManagersQuery();
  const { data: oblastData, isSuccess: isSuccessOblastData } = useGetOblastQuery();
  const [oblasts, setOblasts] = useState([]);


  const { data, isFetching: isLoadingTickets, refetch, error } = useGetTicketsQuery({
    offset: pageSize * (page - 1),
    limit: pageSize,
    created_at_start:
      dateRange && dateRange.length
        ? dayjs(dateRange[0].toISOString()).format("YYYY-MM-DD")
        : null,
    created_at_end:
      dateRange && dateRange.length
        ? dayjs(dateRange[1].toISOString()).format("YYYY-MM-DD")
        : null,
    phone: phoneNumber,
    type_id: type,
    oblastId: oblastId,
    category_id: category,
    source_id: source,
    responsible: responsible,
    status_id: status,
    is_test: isTest ? 1 : 0,
    description: searchText
  },
  );

  useEffect(() => {
    if (oblastData) {
        if(i18n.language === 'en'){
            setOblasts(oblastData.map((item) => ({label: item.label_en, value: item.value})))

        }else{
            setOblasts(oblastData)
        }
    }
}, [oblastData, t])


  useEffect(() => {
    setPage(1)
    setIsfiltered((dateRange?.[0] || dateRange?.[1] || phoneNumber || type || oblastId?.length || category || source || responsible?.length || status || debouncedSearchText?.length)>0)
  }, [dateRange?.[0], dateRange?.[1], phoneNumber, type, oblastId, category, source, responsible, status, debouncedSearchText]);


  useEffect(() => {
    const filters = {
      page: page,
      pageSize: pageSize,
      dateRange:
        dateRange && dateRange.length
          ? [dateRange[0].toISOString(), dateRange[1].toISOString()]
          : null,
      phoneNumber,
      type,
      oblastId,
      category,
      source,
      responsible,
      status,
      searchText,
      isTest,
    };

    saveState("ticketsFilters", filters);
  }, [dateRange, phoneNumber, type, oblastId, category, source, responsible, status, searchText, isTest, page, pageSize]);

  const handleClear = () => {
    setDateRange(null);
    setPhoneNumber(null);
    setDebouncedPhoneNumber(null);
    setType(null);
    setOblastId(null);
    setCategory(null);
    setSource(null);
    setResponsible(null);
    setStatus(null);
    setSearchText(null);
    setDebouncedSearchText(null);
  };

  useEffect(() => {
    if (data) {
      setTickets(data.tickets);
      setTicketsCount(data.count);
    }
  }, [data]);


  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setPhoneNumber(debouncedPhoneNumber);
    }, 1000);

    return () => clearTimeout(debounceTimer);
  }, [debouncedPhoneNumber]);


  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setSearchText(debouncedSearchText);
    }, 1000);

    return () => clearTimeout(debounceTimer);
  }, [debouncedSearchText]);



  const onDateRangeChange = (values) => {
    if (values && values.length) {
      setDateRange(values);
    } else {
      setDateRange(null);
    }
    handlePageChange(1, pageSize);
    refetch();
  };

  const handleIdClick = (ticket) => {
    setSelectedTicket(ticket);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setSelectedTicket(null);
    setIsModalVisible(false);
  };

  const onStatusChange = (ticketId, newStatusId) => {
    setTickets(
      tickets.map((ticket) => {
        if (ticket.id === ticketId) {
          return {
            ...ticket,
            status: statusesData[newStatusId-1].label,
            statusId: newStatusId,
          };
        } else {
          return ticket;
        }
      })
    );
  };

  const handlePageChange = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
  };

  const getPresetRanges = () => {
    const now = dayjs();
    const today = now.clone().startOf('day');
    const yesterday = now.clone().subtract(1, 'day').startOf('day');
    const endOfYesterday = now.clone().subtract(1, 'day').endOf('day');
    const startOfSevenDays = now.clone().subtract(6, 'day').startOf('day');
    const endOfSevenDays = now.clone().endOf('day');
    const startOfWeek = now.clone().startOf('isoWeek');
    const endOfWeek = now.clone().endOf('isoWeek');
    const startOfMonth = now.clone().startOf('month');
    const endOfMonth = now.clone().endOf('month');
    const startOfPrevWeek = now.clone().subtract(1, 'week').startOf('isoWeek');
    const endOfPrevWeek = now.clone().subtract(1, 'week').endOf('isoWeek');
    const startOfPrevMonth = now.clone().subtract(1, 'month').startOf('month');
    const endOfPrevMonth = now.clone().subtract(1, 'month').endOf('month');
  
    return [
      { label: t('Today'), value: [today, now] },
      { label: t('Yesterday'), value: [yesterday, endOfYesterday] },
      { label: t('Yesterday+today'), value: [yesterday, now] },
      { label: t('Last 7 days'), value: [startOfSevenDays, endOfSevenDays] },
      { label: t('Current week'), value: [startOfWeek, endOfWeek] },
      { label: t('Current month'), value: [startOfMonth, endOfMonth] },
      { label: t('Previous week'), value: [startOfPrevWeek, endOfPrevWeek] },
      { label: t('Previous month'), value: [startOfPrevMonth, endOfPrevMonth] },
    ];
  };
  

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "12%",
      render: (text, record) => {
        return (
          <Button
            type={!record.is_test ? "dashed" : "default"}
            style={{ width: "120px", textAlign: "center" }}
            icon={<EditOutlined />}
            onClick={() => handleIdClick(record)}
          >
            {text}
          </Button>
        );
      },
    },
    {
      title: t("Create date"),
      dataIndex: "created_at",
      key: "created_at",
      width: "12%",
    },
    {
      title: t("Region"),
      dataIndex: "oblast",
      key: "oblast",
      width: "17%",
      ellipsis: { showTitle: false },
      render: (region, record) => {
        if (i18n.language === 'en'){return (region?.name_en);}
        if (i18n.language === 'ua'){return (region?.name);}
      },
    },
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
      width: "17%",
      ellipsis: { showTitle: false },
      render: (type, record) => {
        if (i18n.language === 'en'){return (type?.name_en);}
        if (i18n.language === 'ua'){return (type?.name);}
      },
    },
    {
      title: t("Category"),
      dataIndex: "category",
      key: "category",
      width: "17%",
      ellipsis: { showTitle: false },
      render: (category, record) => {
        if (i18n.language === 'en'){return (category?.name_en);}
        if (i18n.language === 'ua'){return (category?.name);}
      },
    },
    {
      title: t("Description"),
      dataIndex: "description",
      key: "description",
      width: "30%",
      ellipsis: { showTitle: false },
      render: (description) => (
        <Tooltip
          placement="left"
          title={description}
          autoAdjustOverflow={true}
        >
          {description}
        </Tooltip>
      ),
    },
    {
      title: t("Phone number"),
      dataIndex: "phone",
      key: "phone",
      width: "17%",
      ellipsis: { showTitle: false },
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      width: "15%",
      render: (text, record) => (
        <Tag
          onClick={() => handleIdClick(record)}
          style={{ width: "150px", textAlign: "center", cursor: "pointer",}}
          color={statusColors[record.statusId-1].color}
          icon={statusColors[record.statusId-1].icon}
        >
          {
          i18n.language === 'en' ? text?.name_en : i18n.language === 'ua' ? text?.name : text?.name
          }
        </Tag>
      ),
    },
  ];

  if (error) {
    return (
      <Alert
        message={t("Error when loading tickets")}
        description={error.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <div className="tickets-page-content">
      <Flex gap="small" wrap="wrap" align="center" justify="center" className="filters-space">
        <RangePicker
          value={dateRange}
          onChange={onDateRangeChange}
          style={{ minWidth: 300 }}
          presets={getPresetRanges()}
        />
        <Input
          value={debouncedPhoneNumber}
          allowClear
          onChange={(e) => setDebouncedPhoneNumber(e.target.value)}
          placeholder={t("Phone number")}
          prefix={<PhoneOutlined />}
          style={{ minWidth: 150, maxWidth: 180 }}
        />
        <Select
          value={oblastId}
          onChange={setOblastId}
          placeholder={t("Region")}
          style={{ minWidth: 180 }}
          allowClear
          showSearch
          options={oblasts}
        />
        <Select
          value={type}
          onChange={setType}
          placeholder={t("Type")}
          style={{ minWidth: 140 }}
          allowClear
          options={typesData?.map((item) => ({label: t(item.label), value: item.value}))}
        />
        <Select
          value={category}
          onChange={setCategory}
          placeholder={t("Category")}
          style={{ minWidth: 160 }}
          allowClear
          options={categoriesData?.map((item) => ({label: t(item.label), value: item.value}))}
        />
        <Select
          value={status}
          onChange={setStatus}
          placeholder={t("Status")}
          style={{ minWidth: 180 }}
          allowClear
          options={statusesData?.map((item) => ({label: t(item.label), value: item.value}))}
        />
      </Flex>

      <Flex gap="small" wrap="wrap" align="center" justify="center" className="filters-space">
      <Select
          value={source}
          onChange={setSource}
          placeholder={t("Source")}
          style={{ minWidth: 180 }}
          allowClear
          options={
            i18n.language === 'ua'
              ? sourcesData?.map((item) => ({ label: item.name, value: item.value }))
              : i18n.language === 'en'
              ? sourcesData?.map((item) => ({ label: item.name_en, value: item.value }))
              : []
          }
        />
        <Select
          value={responsible}
          onChange={setResponsible}
          placeholder={t("Responsible")}
          style={{ minWidth: 180 }}
          allowClear
          showSearch
          options={responsiblesData}
        />
        <Input
          value={debouncedSearchText}
          allowClear
          onChange={(e) => setDebouncedSearchText(e.target.value)}
          placeholder={t("Search by description")}
          style={{ minWidth: 350, maxWidth: 350 }}
        />
        <div style={{minWidth:30}} hidden={!isFiltered}>
            <Tooltip
              hidden={!isFiltered}
              title={t("Clear all filters")}>
              <Button
                size="middle"
                type="dashed"
                icon={<ClearOutlined />}
                onClick={() => {handleClear()}}
              />
              </Tooltip>
        </div>
      </Flex>

      <Table
        size="small"
        loading={isLoadingTickets}
        pagination={{
          position: ["none", "bottomCenter"],
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          hideOnSinglePage: false,
          onShowSizeChange: handlePageChange,
          onChange: handlePageChange,
          pageSizeOptions: [10, 20, 50, 100],
          total: ticketsCount,
        }}
        columns={columns}
        dataSource={tickets}
        rowKey="id"
      />
      {selectedTicket && (
        <TicketDetailsPage
          ticketId={selectedTicket.id}
          visible={isModalVisible}
          onCancel={handleModalClose}
          onStatusChange={onStatusChange}
        />
      )}
    </div>
  );
};

export default TicketsPage;
