import React, { useEffect, useState, useMemo, useRef } from 'react';
import { message, Select, Flex, Spin, Card, Space, Descriptions, Tag, Image, Row, Col, Typography, Divider, Form, Input, Button, Modal, Popconfirm, Popover, Timeline, Tooltip } from 'antd';
import { SyncOutlined, IssuesCloseOutlined, HistoryOutlined, CommentOutlined,  SecurityScanOutlined, MessageOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { PlusCircleOutlined, ClockCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { 
    useGetTicketByIdQuery, 
    useAddCommentMutation, 
    useChangeStatusMutation,
    useGetStatusesQuery,
    useGetTypesQuery,
    useGetCategoriesQuery,
    useGetSourcesQuery,
    useGetSectorsQuery,
    useGetRegionsQuery,
    useGetDistrictsQuery,
    useGetOtgsQuery,
    useGetManagersQuery as useGetResponsibleQuery,
    useUpdateTicketMutation,
    useGetOblastQuery,
    useGetRaionQuery,
    useGetHromadaQuery
} from '../../utils/api/ticketsApi';
import PermissionWrapper from '../../utils/wrappers/PermissionWrapper';
import { usePermission } from '../../utils/hooks/usePermission';
import { AudioPlayer } from '../'
import store from '../../utils/store';
import './TicketDetailsPage.css'
import { getChangeType, getChangeColor, getChangeIcon } from '../../utils/functions/changesLogParser';


const { Title, Text, Link } = Typography;

const permissions = {
    viewTicket: 'view_ticket',
    edit_ticket: 'edit_ticket',
    addComment: 'add_comment',
    changeStatus: 'change_status',
    manageUsers: 'manage_users',
    canViewTicketAudio: 'authentication.can_access_records',
}

const BASE_AUDIO_URL = process.env.REACT_APP_BASE_AUDIO_URL;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const statusColors = [
    {color: "red", icon: <IssuesCloseOutlined />, nextStatuses: [1]}, //new
    {color: "blue", icon: <SecurityScanOutlined />, nextStatuses: [2, 3, 4]}, //in progress
    {color: "orange", icon: <MessageOutlined />, nextStatuses: [0, 4]}, //on hold
    {color: "grey", icon: <SyncOutlined />, nextStatuses: [0, 4 ]}, //rejected
    {color: "green", icon: <SafetyCertificateOutlined />, nextStatuses: []} //closed
  ];




const TicketDetailsPage = ({ ticketId,  visible, onCancel, onStatusChange }) => {
    const { t } = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();
    const [modalVisible, setModalVisible] = useState(visible);
    const haveChangeStatusPermission = usePermission(permissions.changeStatus);
    const [formComments] = Form.useForm();
    const [formEdit] = Form.useForm();
    const [renderKey, setRenderKey] = useState(Date.now());
    const [commentsList, setCommentsList] = useState([]);
    const [comment, setComment] = useState('');
    const [addComment, { isLoading: isLoadingComment }] = useAddCommentMutation();
    const [showAllComments, setShowAllComments] = useState(false);
    const [updateTicketStatus, { isLoading: isLoadingStatus, isFetching: isFetchingStatus }] = useChangeStatusMutation();
    const [editTicket, { isLoading: isLoadingEdit, isFetching: isFetchingEdit, isSuccess:isSuccessEdit, isError: isErrorEdit }] = useUpdateTicketMutation();
    const [timeLine, setTimeLine] = useState(null);
    const [timelineVisible, setTimelineVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const [changeStatusPopoverVisible, setChangeStatusPopoverVisible] = useState(false);

    const [typeId, setTypeId] = useState(null);
    const [types, setTypes] = useState(null);
    const [category, setCategory] = useState(null);
    const [source, setSource] = useState(null);
    const [sector, setSector] = useState(null);
    const [region, setRegion] = useState(null);
    const [district, setDistrict] = useState(null);
    const [otg, setOtg] = useState(null);

    const [oblastId, setOblastId] = useState(null);
    const [raionId, setRaionId] = useState(null);
    const [hromadaId, setHromadaId] = useState(null);

    const [phone, setPhone] = useState(null);
    const [responsible, setResponsible] = useState(null);
    const [status, setStatus] = useState(null);
    const [callDate, setCallDate] = useState(null);
    const [agentId, setAgentId] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [description, setDescription] = useState(null);
    const [publicOrganization, setPublicOrganization] = useState(null);
    const [changesLog, setChangesLog] = useState(null);

    const { data: oblastData, isSuccess: isSuccessOblastData, isLoading: isLoadingOblastData } = useGetOblastQuery();
    const { data: raionData, isSuccess: isSuccessRaionData, isLoading: isLoadingRaionData } = useGetRaionQuery({oblastId},{skip: !oblastId});
    const { data: hromadaData, isSuccess: isSuccessHromadaData, isLoading: isLoadingHromadaData  } = useGetHromadaQuery({raionId},{skip: !raionId || !oblastId});
    
    const [oblasts, setOblasts] = useState([]);
    const [raions, setRaions] = useState([]);
    const [hromadas, setHromadas] = useState([]);

    const { data: statusesData, isLoading: isLoadingStatusesData } = useGetStatusesQuery();
    const { data: ticket, isLoading: isLoadingTicket, refetch, isFetching: isFetchingTicket } = useGetTicketByIdQuery(ticketId);
    const { data: typesData, isLoading: isLoadingTypesData } = useGetTypesQuery({ skip: !editMode});
    const { data: categoriesData, isLoading: isLoadingCategoriesData } = useGetCategoriesQuery({ skip: !editMode});
    const { data: sourcesData, isLoading: isLoadingSourcesData } = useGetSourcesQuery({ skip: !editMode});
    const { data: sectorsData, isLoading: isLoadingSectorsData } = useGetSectorsQuery({ skip: !editMode});

    const { data: regionsData, isLoading: isLoadingRegionsData } = useGetRegionsQuery({ skip: !editMode});
    const { data: districtsData, isLoading: isLoadingDistrictsData } = useGetDistrictsQuery({region},{skip: !region});
    const { data: otgsData, isLoading: isLoadingOtgsData } = useGetOtgsQuery({region, district}, { skip: !region && !district});


  useEffect(() => {
    if (ticket && !isLoadingTicket && !isFetchingTicket) {
        setTypeId(ticket?.type?.id);
        setCategory(ticket?.category);
        setSource(ticket?.source);
        setSector(ticket?.sector);
        setOblastId(ticket?.oblast?.id);
        setRaionId(ticket?.raion?.id);
        setHromadaId(ticket?.hromada?.id);
        setPhone(ticket?.phone);
        setResponsible(ticket?.responsible);
        setStatus(ticket?.status);
        setCallDate(ticket?.call_date);
        setAgentId(ticket?.agent_id);
        setFirstName(ticket?.first_name);
        setDescription(ticket?.description);
        setPublicOrganization(ticket?.public_org);
        setChangesLog(
            ticket?.change_log?.map((l) => {
                return {
                    datetime: l.datetime,
                    change: l.change,
                    user: l.user,
                    change_type: getChangeType(l.change),
                    change_color: getChangeColor(l.change),
                    change_icon: getChangeIcon(l.change),
                }
            }
        )
        );

    };
    }, [ticket, isFetchingTicket, isLoadingTicket]);


    useEffect(() => {
        if (typesData) {
            if(i18n.language === 'en'){
                setTypes(typesData.map((item) => ({label: item.label_en, value: item.value})))
            }else{
                setTypes(typesData)
            }
        }
    }, [typesData, t])

    useEffect(() => {
        if (oblastData) {
            if(i18n.language === 'en'){
                setOblasts(oblastData.map((item) => ({label: item.label_en, value: item.value})))
            }else{
                setOblasts(oblastData)
            }
        }
    }, [oblastData, t])
    
    useEffect(() => {
        if (raionData) {
            if(i18n.language === 'en'){
                setRaions(raionData.map((item) => ({label: item.label_en, value: item.value})))
            }else{
                setRaions(raionData)
            }
        }
    }, [raionData, this])

    useEffect(() => {
        if (hromadaData) {
            if(i18n.language === 'en'){
                setHromadas(hromadaData.map((item) => ({label: item.label_en, value: item.value})))
            }else{
                setHromadas(hromadaData)
            }
        }
    }, [hromadaData, t])


    const onSelectOblast = (v) => {
            setOblastId(v);
            setRaionId(null);
            setHromadaId(null);
            formEdit.setFieldsValue({raion: null});
            formEdit.setFieldsValue({hromada: null});
    }

    const onSelectRaion = (v) => {
            setRaionId(v);
            setHromadaId(null);
            formEdit.setFieldsValue({hromada: null});
    }
    
  useEffect(() => {
    if(visible){
        refetch().then((result) => {
            const ticket = result.data;
            setCommentsList(ticket?.comments);
        });
    }
  }, [visible, refetch, commentsList]);

    useEffect(() => {
        if (isErrorEdit) {
            message.error(t('Failed to save changes'));
        }
        if (isSuccessEdit) {
            message.success(t('Changes saved successfully'));
            refetch();
            setEditMode(false);
        }

    }, [isFetchingEdit, isErrorEdit, isSuccessEdit]);

    const onFinishEdit = (values) => {
        editTicket({ ticketId, ...values })
    };

    const onFinishEditFailed = (errorInfo) => {
        message.error(t('Failed to save changes'));
    };
   
  const onFinishAddComment = () => {
    if (comment.trim()) {
        addComment({ ticketId, comment })
        .then((result) => {
            const addedComment = result.data;
            setCommentsList([...commentsList, addedComment]);
            setComment('');
            formComments.resetFields();
        })
        .catch((error) => {
            console.error('Failed to add comment:', error);
        });
    }
    };
  
  const onFinishAddCommentFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    };

    const onCancelEdit = () => {
        setEditMode(false);
        refetch();
    }

  if (isLoadingTicket) {
    return (
        <Modal 
        closable={false} 
        open={modalVisible} 
        onCancel={onCancel} 
        footer={null} 
        width={"90%"} 
        styles={{
            body: { height:'92vh', overflowY:'auto', display: "flex", justifyContent: "center", alignItems: "center", justifyItems: "center"},
            mask: { top:"20px" }
        }}
        >
            <Spin size="large" />
        </Modal>
    );
  }
  
  const changeStatus = (newStatusId) => {
    updateTicketStatus({ ticketId, newStatusId })
      .then(() => {
        refetch();
        onStatusChange(ticketId, newStatusId);
        if(statusColors[newStatusId]?.nextStatuses?.length === 0)
        {
            setChangeStatusPopoverVisible(false);
        }
      })
      .catch((error) => {
        console.error('Failed to update status:', error);
      });
  };

  const toggleShowAllComments = () => {
    setShowAllComments(!showAllComments);
  };

  const handleChangeStatusPopoverVisible = (visible) => {
    if(statusColors[status?.id-1]?.nextStatuses?.length === 0)
        {
            setChangeStatusPopoverVisible(false);
        }
        else
        {
            setChangeStatusPopoverVisible(visible);
        }
    };

  const displayedComments = showAllComments ? commentsList : commentsList.slice(-1);

  const changeStatusContent = () => {
    return (
        <Space direction="vertical">
            {
            statusColors[status?.id-1]?.nextStatuses?.map((nextStatus) => {
                return (
                    <Popconfirm
                        key={nextStatus}
                        title={t("Change status")}
                        onConfirm={()=>changeStatus(nextStatus+1)}
                    >
                    <Button key={nextStatus} size="small" type='ghost'>
                        <Tag 
                            type="primary" 
                            style={{width: '170px', textAlign: "center"}}
                            color={statusColors[nextStatus]?.color} 
                            icon={statusColors[nextStatus]?.icon}
                            >
                                {t(statusesData?.find((st) => st.value-1 === nextStatus)?.label)}
                        </Tag>
                    </Button>
                    </Popconfirm>
                    )
                })
            }
        </Space>
    );
  }

  return (
    <Modal 
        closable={false} 
        open={modalVisible} 
        onCancel={onCancel} 
        // destroyOnClose
        footer={null} 
        width={"90%"} 
        // bodyStyle={}
        styles={{body:{ maxHeight:'92vh', overflowY:'auto', overflowX: "hidden"}, mask:{ top:"20px" }}}
        cancelButtonProps={{
            danger:"true", 
            size:'small', 
            block:true,
        }}
        >
            <PermissionWrapper requiredPermission={permissions.edit_ticket}>
                <Row style={{paddingBottom:"6px"}}>
                    <Col span={24}>
                    <Flex
                        // gap={"small"}
                        align="center"
                        justify="flex-end"
                        style={{width:"100%"}}
                    >
                        {/* <Space direction='horizontal' size='small'> */}
                        {!editMode ?
                            <Button size='small' style={{width:"100px"}} type='primary' onClick={() => setEditMode(true)} >{t("Edit")}</Button>
                        :
                            <>
                            <Button size='small' style={{width:"100px"}} type='primary' onClick={() => formEdit.submit()} disabled={isLoadingEdit} loading={isLoadingEdit}>{t("Save")}</Button>
                            <Button size='small' style={{width:"100px"}} danger onClick={onCancelEdit}>{t("Cancel")}</Button>
                            </>
                        }
                        {/* <Button size='small' style={{width:"100px"}} type='primary' onClick={() => setModalVisible(false)}>{t("Close")}</Button> */}
                        {/* </Space> */}
                    </Flex>
                    </Col>
                </Row>
            </PermissionWrapper>
            <Row>
            <Col span={timelineVisible ? 16 : 24}>
            <Card>
                <Form
                    name='edit-ticket-form'
                    key={renderKey}
                    form={formEdit}
                    onFinish={onFinishEdit}
                    onFinishFailed={onFinishEditFailed}
                    initialValues={{
                        type: ticket?.type?.id, 
                        category: ticket?.category?.id, 
                        sector: ticket?.sector?.id, 
                        public_org: ticket?.public_org,
                        oblast: ticket?.oblast?.id, 
                        raion: ticket?.raion?.id, 
                        hromada: ticket?.hromada?.id,
                        first_name: ticket?.first_name,
                        phone: ticket?.phone,
                        description: ticket?.description,
                    }}
                >
                <Row className="header-row">
                    <Col span={6}>
                        <Title 
                            level={3} 
                            style={{marginTop: '0px', marginBottom: '0px'}}
                            copyable={{
                                text: `${BASE_URL}/tickets/${ticketId}`,
                                tooltips: t("Copy ticket link to clipboard"),
                            }}
                            
                            >
                                {t("Ticket")} #{ticketId}
                        </Title>
                    </Col>
                    <Col span={6}>
                        <Flex vertical={false} gap="small" align="center" justify="flex-start" style={{"minWidth": "100%"}}>
                            <Col span={6}>
                                <Text type="secondary">{t("Type")}: </Text>
                            </Col>
                            <Col span={18}>
                                <Form.Item 
                                    hidden={!editMode} 
                                    name="type" 
                                    style={{marginBottom:"0px"}}
                                    rules={[{ required: true, message: t("Please select type.") }]}
                                    >
                                    <Select 
                                        style={{maxWidth: "150px", padding: "0px"}} 
                                        size="small"
                                        value={typeId}
                                        options={types}
                                        // optionRender={(item) => {return (<Text>{t(item.label.trim())}</Text>)}}
                                        // labelRender={(item) => {return (<Text>{t(item.label.trim())}</Text>)}}
                                        loading={isLoadingTypesData}
                                        // onChange={(value) => setTypeId(value)}
                                        />
                                </Form.Item>
                                <Text hidden={editMode} >
                                    {i18n.language === 'en' ? ticket?.type?.name_en : ticket?.type?.name}
                                </Text>
                            </Col>
                        </Flex>
                    </Col>
                    <Col span={6}>
                        <Flex vertical={false} gap="small" align="center" justify="flex-start" style={{"minWidth": "100%"}}>
                            <Col span={6}>
                                <Text type="secondary">{t("Category")}: </Text>
                            </Col>
                            <Col span={18}>
                                <Form.Item 
                                    hidden={!editMode} 
                                    name="category" 
                                    style={{marginBottom:"0px"}}
                                    rules={[{ required: true, message: t("Please select category.") }]}
                                    >
                                    <Select 
                                        style={{maxWidth: "180px"}} 
                                        size="small"
                                        value={ticket?.category?.id} 
                                        options={categoriesData}
                                        optionRender={(item) => {return (<Text>{t(item.label.trim())}</Text>)}}
                                        labelRender={(item) => {return (<Text>{t(item.label.trim())}</Text>)}}
                                        loading={isLoadingCategoriesData}
                                        />
                                </Form.Item>
                                <Text hidden={editMode}>
                                    {i18n.language === 'en' ? ticket?.category?.name_en : ticket?.category?.name}
                                </Text>
                            </Col>

                        </Flex>
                    </Col>
                    <Col span={6}>
                        <Flex vertical={false} gap="small" align="center" justify="flex-start" style={{"minWidth": "100%"}}>
                            <Col span={6} >
                                <Text type="secondary">{t("Sector")}: </Text>
                            </Col>
                            <Col span={18}>
                                <Form.Item 
                                    hidden={!editMode} 
                                    name="sector" 
                                    style={{marginBottom:"0px", margin:"0px"}}
                                    rules={[{ required: true, message: t("Please select sector.") }]}
                                    >
                                    <Select 
                                        style={{maxWidth: "230px"}}
                                        size="small"
                                        value={ticket?.sector?.id} 
                                        options={sectorsData}
                                        optionRender={(item) => {return (<Text>{t(item.label.trim())}</Text>)}}
                                        labelRender={(item) => {return (<Text>{t(item.label.trim())}</Text>)}}
                                        loading={isLoadingSectorsData}
                                        />
                                </Form.Item>
                                <Text hidden={editMode}>
                                    {i18n.language === 'en' ? ticket?.sector?.name_en : ticket?.sector?.name}
                                </Text>
                            </Col>
                        </Flex>
                    </Col>
                </Row>
                <Divider style={{margin:0, marginTop: 8, marginBottom: 8, fontSize:12, color:'grey'}}/>
                <Row>
                    <Col span={12}>
                        <Flex vertical={true} gap="small" align="center" style={{"minWidth": "100%"}}>
                            <Flex vertical={false} gap="small" align="center" style={{"minWidth": "100%"}}>
                                <Col span={6}>
                                    <Text type="secondary">{t("Source")}: </Text>
                                </Col>
                                <Col span={18}>
                                    <Text >
                                        {
                                            i18n.language === 'en' ? ticket?.source?.name_en : ticket?.source?.name
                                        }
                                    </Text>
                                </Col>
                            </Flex>
                            <Flex vertical={false} gap="small" align="center" style={{"minWidth": "100%"}}>
                                <Col span={6}>
                                    <Text type="secondary">{t("Name of non-governmental organization")}: </Text>
                                </Col>
                                
                                <Col span={18}>
                                <Form.Item 
                                    hidden={!editMode} 
                                    name="public_org" 
                                    style={{maxWidth:"300px", marginBottom:"0px", margin:"0px"}}
                                    >
                                    <Input type="text" size="small"
                                        value={ticket?.public_org}
                                    ></Input>
                                </Form.Item>
                                    <Text hidden={editMode}>{publicOrganization}</Text>
                                </Col>
                            </Flex>
                        </Flex>
                    </Col>
                    <Col span={6}>
                        <Flex vertical={true} gap="small" align="center" style={{"minWidth": "100%"}}>
                            <Flex vertical={false} gap="small" align="center" justify="flex-start" style={{"minWidth": "100%"}}>
                                <Col span={6}>
                                    <Text type="secondary">{t("Region")}: </Text>
                                </Col>
                                <Col span={18}>
                                    <Form.Item 
                                        hidden={!editMode} 
                                        name="oblast" 
                                        style={{marginBottom:"0px", margin:"0px"}}
                                        rules={[{ required: true, message: t("Please select region.") }]}
                                        >
                                        <Select 
                                            style={{maxWidth: "230px"}} 
                                            size="small"
                                            value={oblastId}
                                            options={oblasts}
                                            loading={isLoadingOblastData}
                                            onSelect={onSelectOblast}
                                            />
                                    </Form.Item>
                                    <Text hidden={editMode}>
                                        {i18n.language === 'en' ? ticket?.oblast?.name_en : ticket?.oblast?.name}
                                    </Text>
                                </Col>
                            </Flex>
                            <Flex vertical={false} gap="small" align="center" justify="flex-start" style={{"minWidth": "100%"}}>
                                <Col span={6}>
                                    <Text type="secondary">{t("District")}: </Text>
                                </Col>
                                <Col span={18}>
                                    <Form.Item 
                                        hidden={!editMode} 
                                        name="raion" 
                                        style={{marginBottom:"0px", margin:"0px"}}
                                        rules={[{ required: true, message: t("Please select district.") }]}
                                        >
                                        <Select 
                                            style={{maxWidth: "230px"}} 
                                            size="small"
                                            value={raionId} 
                                            options={raions}
                                            loading={isLoadingRaionData}
                                            onSelect={onSelectRaion}
                                            />
                                    </Form.Item>
                                    <Text hidden={editMode}>
                                        {i18n.language === 'en' ? ticket?.raion?.name_en : ticket?.raion?.name}
                                    </Text>
                                </Col>
                            </Flex>
                            <Flex vertical={false} gap="small" align="center" justify="flex-start" style={{"minWidth": "100%"}}>
                                <Col span={6}>
                                    <Text type="secondary">{t("Community")}: </Text>
                                </Col>
                                <Col span={18}>
                                    <Form.Item 
                                        hidden={!editMode} 
                                        name="hromada" 
                                        style={{marginBottom:"0px", margin:"0px"}}
                                        rules={[{ required: true, message: t("Please select community.") }]}
                                        >
                                        <Select 
                                            style={{maxWidth: "230px"}} 
                                            size="small"
                                            value={hromadaId} 
                                            options={hromadas}
                                            loading={isLoadingHromadaData}
                                            />
                                    </Form.Item>
                                    <Text hidden={editMode}>
                                        {i18n.language === 'en' ? ticket?.hromada?.name_en : ticket?.hromada?.name}
                                    </Text>
                                </Col>
                            </Flex>
                        </Flex>
                    </Col>
                    <Col span={6}>
                        <Flex vertical={false} gap="small" align="center" justify="flex-start" style={{"minWidth": "100%"}}>
                            {
                                ticket?.files?.map((file) => {
                                    return (
                                        <Image
                                            key={file}
                                            width={100}
                                            src={`${BASE_URL}/${file}`}
                                        />
                                    )
                                })
                            }
                        </Flex> 
                    </Col>

                </Row>
                <Divider style={{margin:0, marginTop: 8, marginBottom: 8, fontSize:12, color:'grey'}}/>
                <Row>
                    <Col span={12}>
                        <Flex vertical={false} gap="small" align="center" justify="flex-start" style={{"minWidth": "100%"}}>
                            <Col span={6}>
                                <Text type="secondary">{t("Name")}: </Text>
                            </Col>
                            <Col span={18}>
                                <Form.Item 
                                    hidden={!editMode} 
                                    name="first_name" 
                                    style={{maxWidth:"300px", marginBottom:"0px", margin:"0px"}}
                                    rules={[{ required: true, message: t("Please input name.") }]}
                                    >
                                    <Input type="text" size="small"
                                        value={ticket?.first_name}
                                    ></Input>
                                </Form.Item>
                                    <Text hidden={editMode}>{firstName}</Text>
                            </Col>
                        </Flex>
                    </Col>
                    <Col span={6}>
                        <Flex vertical={false} gap="small" align="center" justify="flex-start" style={{"minWidth": "100%"}}>
                            <Col span={6}>
                                <Text type="secondary">{t("Phone")}: </Text>
                            </Col>
                            <Col span={18}>
                                <Form.Item 
                                    hidden={!editMode} 
                                    name="phone" 
                                    style={{maxWidth:"300px", marginBottom:"0px", margin:"0px"}}
                                    rules={[
                                        { required: true, message: t("Please input phone number.") },
                                        { pattern: /^\+380\d{9}$/, message: t("Please input valid phone number in format +380xxxxxxxxx") }
                                    ]}
                                    >
                                    <Input type="text" size="small"
                                        value={ticket?.phone}
                                    ></Input>
                                </Form.Item>
                                <Text hidden={editMode}>{phone}</Text>
                            </Col>
                        </Flex>
                    </Col>
                </Row>
                <Divider style={{margin:0, marginTop: 8, marginBottom: 8, fontSize:12, color:'grey'}}/>
                <Row>
                    <Col span={6}>
                        <Flex gap="small" align="center">
                        {source?.id === 1 && (
                            <>
                            <Col span={12}>
                                <Text type="secondary">{t("Agent")}: </Text>
                            </Col>
                            <Col span={12}>
                                <Text >{agentId}</Text>
                            </Col>
                            </>
                        )}
                        </Flex>
                    </Col>
                    <Col span={6}>
                        <Flex gap="small" align="center">
                        {source?.id === 1 && (
                            <>
                            <Col span={10}>
                                <Text type="secondary">{t("Call date")}: </Text>
                            </Col>
                            <Col span={14}>
                                <Text >{callDate && dayjs(callDate).format("DD.MM.YYYY HH:mm")}</Text>
                            </Col>
                            </>
                        )}
                        </Flex>
                    </Col>
                    <Col span={6}>
                        <Popover
                            placement="topRight"
                            content={
                                isLoadingTicket || isLoadingStatus || isFetchingStatus || isFetchingTicket ?
                                <Button size="small" type='ghost' style={{width: '170px', textAlign: "center"}}>
                                        <Spin size="medium" />
                                </Button>
                                    :
                                changeStatusContent
                            }
                            title={t("Change status")}
                            trigger="click"
                            open={changeStatusPopoverVisible && haveChangeStatusPermission}
                            arrow={false}
                            onOpenChange={handleChangeStatusPopoverVisible}
                        >
                            <Button size="small" type='ghost'>
                                <Tag 
                                    style={{width: '170px', textAlign: "center"}} 
                                    color={statusColors[status?.id-1]?.color} 
                                    icon={statusColors[status?.id-1]?.icon}
                                    >
                                    {i18n.language === 'en' ? ticket?.status?.name_en : ticket?.status?.name} 
                                </Tag>
                            </Button>
                        </Popover>
                        <Tooltip title={t("History of changes")}>
                            <HistoryOutlined 
                                style={{fontSize: '20px', color: '#4078F2', cursor: 'pointer'}}
                                onClick={()=>setTimelineVisible(!timelineVisible)}
                                />
                        </Tooltip>
                    </Col>
                    <Col span={6}>
                        <Flex gap="small" align="center">
                            <Col span={10}>
                                <Text type="secondary">{t("Responsible")}: </Text>
                            </Col>
                            <Col span={14}>
                                <Link >{responsible?.username}</Link>
                            </Col>
                        </Flex>
                    </Col>
                </Row>
                <Divider style={{margin:0, marginTop: 8, marginBottom: 8, fontSize:12, color:'grey'}}/>
                <Row>
                    <Col span={24}>
                        <Row>
                            <Flex vertical={false} gap="small" align="center" style={{"minWidth": "100%"}}>
                                <Col span={2}>
                                    <Text type="secondary">{t("Description")}: </Text>
                                </Col>
                                <Col span={22}>
                                <Form.Item hidden={!editMode} name="description" style={{maxWidth:"100%", marginBottom:"0px", margin:"0px"}}>
                                    <Input.TextArea  type="text" size="small"
                                        value={ticket?.description}
                                    ></Input.TextArea >
                                </Form.Item>
                                <Text hidden={editMode}>{description}</Text>
                                </Col>
                            </Flex>
                        </Row>
                        {source?.id === 1 && (
                            <>
                            {ticket.audio_link ?
                            <>
                            <Divider style={{margin:5, fontSize:12, color:'grey'}}>{t("Call audio record")}</Divider>
                            <Row>
                                <Col span={24} >
                                    <AudioPlayer 
                                        id={1} 
                                        audioUrl={`${BASE_AUDIO_URL}${ticket.audio_link}`} 
                                        playPause={false} 
                                        playAfterLoad={false}
                                        onIsPlayingChange={()=>{}}
                                        onLoadingChange={()=>{}}
                                        onCurrentDurationChange={()=>{}}
                                        token={store.getState().auth.access}
                                        />
                                </Col>
                            </Row>
                            </>
                            :
                            <>
                            
                            <Divider style={{margin:0, marginTop: 8, marginBottom: 8, fontSize:12, color:'grey'}}>{t("Call audio record")}</Divider>
                            <Row>
                                <Col span={24} className="audio-record-text">
                                    <Text type="secondary" >{t("The call audio record will be available approximately two hours after its completion.")}</Text>
                                </Col>
                            </Row>
                            </>
                            }
                            </>
                        )}
                    </Col>
                </Row>
            </Form>
            </Card>
            {commentsList?.length > 0 && 
                <>
                <Divider style={{margin:0, marginTop: 8, marginBottom: 8, fontSize:12, color:'grey'}}>
                    <Space>
                        <Text type="secondary">{t("Comments")}({commentsList.length})</Text>
                        {commentsList.length > 1 ?
                        <Link onClick={toggleShowAllComments}>
                            <CommentOutlined/>{' '}
                            {!showAllComments ?  t("Show all") : t("Hide")}
                        </Link> : null}
                    </Space>
                </Divider>
                <Card size="small">
                    <Row>
                        <Col span={24}>
                            {displayedComments?.map((commentItem) => (
                                <div key={`d${commentItem?.id}`}>
                                <Divider 
                                    style={{margin:0, marginTop: 8, marginBottom: 8, fontSize:12, color:'#adadad'}} 
                                    orientation="left" 
                                    orientationMargin="0">
                                        {`${commentItem?.created_by?.username} ${t("added a comment")} - ${dayjs(commentItem?.created_at).format("DD.MM.YYYY HH:mm")}`}
                                </Divider>
                                <Flex vertical={false} gap="small" align="center" style={{"minWidth": "100%"}}>
                                        <Col span={24}>
                                            <Text >{commentItem?.text}</Text>
                                        </Col>
                                </Flex>
                                </div>
                            ))}
                            
                        </Col>
                    </Row>
                </Card>
                </>
            }
            <PermissionWrapper requiredPermission={permissions.addComment}>
            <Divider style={{margin:0, marginTop: 8, marginBottom: 8, fontSize:12, color:'grey'}}>{t("Add comment")}</Divider>
            <Card size="small">
                <Row>
                    <Col span={24}>
                        <Form
                            form={formComments}
                            name="basic"
                            onFinish={onFinishAddComment}
                            onFinishFailed={onFinishAddCommentFailed}
                            >
                                <Form.Item name="comment" >
                                    <Input.TextArea 
                                        placeholder={t("Write your comment here")} 
                                        autoSize={{ minRows: 4, }}
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                        />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={isLoadingComment}>
                                        {t("Add comment")}
                                    </Button>
                                </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Card>
            </PermissionWrapper>
            </Col>
            <Col span={timelineVisible ? 8 : 0} style={{paddingLeft:"12px", minHeight:"100%"}}>
                <Card size="small" style={{minHeight:"100%"}}>
                    <Timeline 
                        mode="left" 
                        items={
                            changesLog?.map((change) => {
                                return {
                                    color: change?.change_color,
                                    dot: change?.change_icon,
                                    children: 
                                        <Row span={24}>
                                            <Col span={6}>
                                                <Text style={{fontSize:"11px"}} type="secondary" >
                                                    {dayjs(change?.datetime).format("DD.MM.YYYY HH:mm")}
                                                </Text>
                                            </Col>
                                            <Col span={5}>
                                                <Link style={{fontSize:"12px"}} type="link" >
                                                    {t(change?.user?.username)}
                                                </Link>
                                            </Col>
                                            <Col span={5}>
                                                <Text style={{fontSize:"12px"}} type="secondary" >
                                                    {t(change?.change_type)}
                                                </Text>
                                            </Col>
                                            <Col span={8}>
                                                <Text style={{fontSize:"12px"}} type="secondary" >
                                                    {change?.change.replace(change?.change_type, "").trim()}
                                                </Text>
                                            </Col>
                                        </Row>,
                                }
                            })}
                            
                        //     [
                        //     {label: dayjs(callDate).format("DD.MM.YYYY HH:mm"), children: <Tag color="blue">{t("Call date")}</Tag>},
                        //     {label: dayjs(callDate).format("DD.MM.YYYY HH:mm"), children: <Tag color="blue">{t("Call date")}</Tag>},
                        // ]
                    
                    />
                </Card>
            </Col>
            </Row>
    </Modal>
   
  );
};

export default TicketDetailsPage;