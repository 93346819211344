import { PlusCircleOutlined, ClockCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';


export function getChangeType(change) {
    if (change === "ticket created") return "ticket created";
    if (change.includes("changed status")) return "changed status";
    if (change.includes("added comment")) return "added comment";
  }
  
export function getChangeIcon(change) {
    // Assuming you convert icons to some serializable format or handle them in the component
    if (change === "ticket created") return <PlusCircleOutlined/>;
    if (change.includes("changed status")) return <ClockCircleOutlined/>;
    if (change.includes("added comment")) return <InfoCircleOutlined/>;
  }
  
export function getChangeColor(change) {
    if (change === "ticket created") return "green";
    if (change.includes("changed status")) return "blue";
    if (change.includes("added comment")) return "gray";
  }