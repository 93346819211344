import { GoogleReCaptchaProvider, useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Input, Select, Radio, Button, Form, Typography, Card, Result, Flex, Layout, Tooltip, Grid, Upload, Col, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next'
import { 
    useGetTypesQuery, 
    useGetCategoriesQuery, 
    useGetRegionsQuery, 
    useGetDistrictsQuery, 
    useGetOtgsQuery, 
    useGetSectorsQuery,
    useGetOblastQuery,
    useGetRaionQuery,
    useGetHromadaQuery,
} from '../../utils/api/ticketsApi'
import { useCreateTicketMutation } from '../../utils/api/ticketsApi'
import { ConfigProvider } from 'antd';
import { GB, UA } from 'country-flag-icons/react/3x2'
import enUS from 'antd/locale/en_US';
import ukUA from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';


const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  

const { Header, Content, Footer } = Layout;
const { Title, Text, Link } = Typography
const { useBreakpoint } = Grid;

const TicketCreatePage = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const screens = useBreakpoint();
    const [renderKey, setRenderKey] = useState(Date.now());
    const [language, setLanguage] = useState('ua');
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [form] = Form.useForm();
    const [type, setType] = useState(null);
    const [sector, setSector] = useState(null);
    const [category, setCategory] = useState(null);
    const [oblastId, setOblastId] = useState(null);
    const [raionId, setRaionId] = useState(null);
    const [hromadaId, setHromadaId] = useState(null);
    const [ticketId, setTicketId] = useState(null);
    
    const { data: typesData } = useGetTypesQuery();
    const { data: categoriesData } = useGetCategoriesQuery();
    const { data: sectorsData } = useGetSectorsQuery();
    const { data: oblastData, isSuccess: isSuccessOblastData } = useGetOblastQuery();
    const { data: raionData } = useGetRaionQuery({oblastId},{skip: !oblastId});
    const { data: hromadaData } = useGetHromadaQuery({raionId},{skip: !raionId || !oblastId});
    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [oblasts, setOblasts] = useState([]);
    const [raions, setRaions] = useState([]);
    const [hromadas, setHromadas] = useState([]);


    const [createTicket, {result, isLoading, isSuccess, isError}] = useCreateTicketMutation();

    
    const [fileList, setFileList] = useState([]);


    const handleChange = ({ fileList: newFileList }) => {
        newFileList.map((file) => {
            console.log(file.originFileObj)
        })
        setFileList(newFileList);
    };


    const onFinish = async (values) => {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available.');
          return;
        }
      
        if (values?.public_org === "") {
          values.public_org = "немає інформації";
        }
      
        const recaptcha = await executeRecaptcha("ticket_create");
        const vals = { ...values, recaptcha };
      
        try {
            const response = await createTicket({ ...vals, files: fileList }).unwrap();
            setTicketId(response?.uid);
            form.resetFields();
            setCategory(null);
            setSector(null);
            setType(null);
            setOblastId(null);
            setRaionId(null);
            setHromadaId(null);
            setFileList([]);
        } catch (error) {
          console.log(error);
        }
      };

    useEffect(() => {
        setLanguage("ua");
        i18n.changeLanguage("ua");
    }, []);

    useEffect(() => {
        if(language === 'en'){
            if (oblastData) {setOblasts(oblastData.map((item) => ({label: item.label_en, value: item.value})))};
            if (raionData) {setRaions(raionData.map((item) => ({label: item.label_en, value: item.value})))};
            if (hromadaData) {setHromadas(hromadaData.map((item) => ({label: item.label_en, value: item.value})))};
            if (typesData) {setTypes(typesData.map((item) => ({label: item.name_en, value: item.value})))};
            if (categoriesData) {setCategories(categoriesData.map((item) => ({label: item.name_en, value: item.value})))};
            if (sectorsData) {setSectors(sectorsData.map((item) => ({label: item.name_en, value: item.value})))};
        }else{
            if (oblastData) {setOblasts(oblastData)};
            if (raionData) {setRaions(raionData)};
            if (hromadaData) {setHromadas(hromadaData)};
            if (typesData) {setTypes(typesData)};
            if (categoriesData) {setCategories(categoriesData)};
            if (sectorsData) {setSectors(sectorsData)};
        }
    }, [oblastData, raionData, hromadaData, language])

      const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
        setRenderKey(Date.now());
      };

    const formItemLayout = {
        labelAlign: 'right',
        labelCol: {xl: {span: 5}, sm: {span: 10}, xs: {span: 24} },
        wrapperCol: {xl: {span: 16}, sm: {span: 11}, xs: {span: 24} },
      };

      const headerLayout = { xl: { span: 5 }, sm: { span: 24 }, xs: { span: 24 } };
      const headerLayout2 = { xl: { span: 14 }, sm: { span: 24 }, xs: { span: 24 } };
      const headerLayout3 = { xl: { span: 5 }, sm: { span: 24 }, xs: { span: 24 } };
    

    return (
        <ConfigProvider 
            locale={language === 'en' ? enUS : ukUA} 
            localeAntd={language === 'en' ? enUS : ukUA}
            theme={{
                token: {
                colorPrimary: '#00acee', // Set your primary color here
                },
            }}
            >
                <Col {...{xl:{span:20, pull: 2, push: 2}, }}>
                    {isSuccess && ticketId ?
                    <Result
                            status="success"
                            title={t("Thank you for your application!")}
                            subTitle={t("Your application number: ")}
                            extra={[
                                <Title level={2} copyable>{ticketId}</Title>,
                                <Button key="again" onClick={()=>setTicketId(null)}>{t("Register another")}</Button>,
                                <Button key="check" onClick={()=>{
                                    navigate("/check_ticket_status");
                                }}>{t("Check application status")}</Button>,
                            ]}
                        />
                    :
                    <Card
                        title={
                        <>
                            <Row>
                            {!screens.xl && (
                            <Col xs={24} sm={24} xl={0} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                                <img style={{ maxWidth: "150px" }} alt="unicef" src={`${process.env.PUBLIC_URL}/unicef_${language}.png`} />
                                <img style={{ maxWidth: "150px" }} alt="hotline" src={`${process.env.PUBLIC_URL}/hotline_${language}.png`} />
                            </Col>
                            )}
                            {screens.xl && (
                            <Col {...headerLayout} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img style={{ maxWidth: "200px" }} alt="unicef" src={`${process.env.PUBLIC_URL}/unicef_${language}.png`} />
                            </Col>
                            )}
                            <Col {...headerLayout2} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Row>
                                <Col span={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Title style={{ wordBreak: 'break-word', whiteSpace: 'normal', textAlign: 'center' }} strong={true} level={2}>{t("Create an Appeal")}</Title>
                                </Col>
                                </Row>
                                <Row>
                                <Col span={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Col span={14} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Title style={{ wordBreak: 'break-word', whiteSpace: 'normal', textAlign: 'center' }} italic strong level={5} type="primary">
                                    {t("Leave feedback about our work, services and help.")}
                                    </Title>
                                    </Col>
                                </Col>
                                </Row>
                            </Col>
                            {screens.xl && (
                            <Col {...headerLayout3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img style={{ maxWidth: "200px" }} alt="hotline" src={`${process.env.PUBLIC_URL}/hotline_${language}.png`} />
                            </Col>
                            )}
                            </Row>
                        </>
                        }
                        style={{ width: "100%", padding: 4, marginTop: 4, margin: "auto" }}
                    >
                        
                        <Col {...{
                            xl: { offset: 0, span: 24, },
                            sm: { offset: 10, span: 12, },
                            xs: { offset: 0, span: 24, },
                        }} style={{paddingTop:"12px"
                        }}>
                        <Flex justify='center' align='center' style={{paddingBottom:"16px"}}>
                            <Radio.Group buttonStyle="solid" onChange={(v)=>{changeLanguage(v.target.value)}} defaultValue={language} optionType="button">
                                <Tooltip className="main-layout-language-radio" title={t('English')}><Radio.Button  value="en"><GB className="flag-icon"/></Radio.Button></Tooltip>
                                <Tooltip className="main-layout-language-radio" title={t('Українська')}><Radio.Button value="ua"><UA className="flag-icon"/></Radio.Button></Tooltip>
                            </Radio.Group>
                        </Flex>
                        </Col>
                        <Form
                            name="register"
                            {...formItemLayout}
                            form={form}
                            onFinish={onFinish}
                        >
                            <Form.Item 
                                name="first_name"
                                label={t('Name')}
                                rules={[{ required: true, message: t("Please input your name.") }]}
                            >
                                <Input 
                                    type='text'

                                />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                label={t("Phone")}
                                placeholder={"+380xxxxxxxxx"}
                                rules={[
                                    { required: true, message: t("Please input your phone number.") },
                                    { pattern: /^\+380\d{9}$/, message: t("Please input valid phone number in format +380xxxxxxxxx.") }
                                ]}
                            >
                                <Input 
                                    type="phone"
                                />
                            </Form.Item>
                            
                            <Form.Item 
                                name="oblast"
                                label={t('Region')}
                                rules={[{ required: true, message: t("Please select your region.") }]}
                            >
                                <Select
                                    placeholder={t("Select region")}
                                    onChange={(value) => {
                                        if(oblastId !== value){
                                            setOblastId(value)
                                            setRaionId(null)
                                            setHromadaId(null)
                                            form.setFieldsValue({ raion: null });
                                            form.setFieldsValue({ hromada: null });
                                        }
                                    }}
                                    options={oblasts}
                                    value={oblastId}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                            
                            <Form.Item 
                                name="raion"
                                label={t('District')}
                                rules={[{ required: true, message: t("Please select your district.") }]}
                            >
                                <Select
                                    placeholder={t("Select district")}
                                    onChange={(value) => {
                                        if(oblastId !== value){
                                            setRaionId(value)
                                            setHromadaId(null)
                                            form.setFieldsValue({ hromada: null });
                                        }
                                    }}
                                    options={raions}
                                    value={raionId}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                            
                            <Form.Item 
                                name="hromada"
                                label={t("Territorial community")}
                                rules={[{ required: true, message: t("Please select your territorial community.") }]}
                            >
                                <Select
                                    placeholder={t("Select territorial community")}
                                    onChange={(value) => setHromadaId(value)}
                                    // style={{ width: "80%" }}
                                    options={hromadas}
                                    value={hromadaId}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                            <Form.Item 
                                name="type"
                                label={t("Type")}
                                rules={[{ required: true, message: t("Please select type of application.") }]}
                            >
                                <Radio.Group
                                    optionType="button"
                                    buttonStyle="solid"
                                    placeholder={t("Select type")}
                                    onChange={(value) => setType(value)}
                                    // style={{ width: "80%" }}
                                    options={types}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                            <Form.Item 
                                name="category"
                                label={t("Category")}
                                rules={[{ required: true, message: t("Please select category of application.") }]}
                            >
                                <Radio.Group
                                    optionType="button"
                                    buttonStyle="solid"
                                    placeholder={t("Select category")}
                                    onChange={(value) => setCategory(value)}
                                    // style={{ width: "80%" }}
                                    options={categories}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                            <Form.Item 
                                name="sector"
                                label={t("Theme")}
                                rules={[{ required: true, message: t("Please select theme of application.") }]}
                            >
                                <Select
                                    placeholder={t("Select theme")}
                                    onChange={(value) => setSector(value)}
                                    options={sectors}
                                    optionRender={(item) => {return (<Text>{item.label.trim()}</Text>)}}
                                    labelRender={(item) => {return (<Text>{item.label.trim()}</Text>)}}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                            <Form.Item 
                                name="description"
                                label={t("Description")}
                                rules={[{ required: true, message: t("Please describe your application.") }]}
                            >
                                <Input.TextArea 
                                />
                            </Form.Item>
                            <Form.Item 
                                style={{height:100}}
                                name="public_org"
                                label={
                                    <Row style={{height:50}}>
                                    {t("Name of non-governmental organization")},
                                    <br />
                                    {t("who provided help (if exists)")}
                                    </Row>
                                }
                                >
                                <Input 
                                    type="text"
                                    style={{height:50}}
                                    placeholder={t("Name of non-governmental organization")}
                                />
                            </Form.Item>

                            <Form.Item
                                name="files"
                                label={t("Add photo")}
                            >
                                <Upload
                                    listType="picture-card"
                                    accept="image/*,.pdf"
                                    multiple
                                    fileList={fileList}
                                    beforeUpload={() => false}
                                    onChange={handleChange}
                                    showUploadList={{
                                        showPreviewIcon: false,  // Hide the preview icon
                                        showRemoveIcon: true,    // Show the remove icon
                                      }}
                                    >
                                    {fileList.length < 3 && (
                                        <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>{t("Upload")}</div>
                                        </div>
                                    )}
                                </Upload>
                            </Form.Item>

                            <Form.Item                     
                                wrapperCol={{
                                    xl: { offset: 5, span: 16, },
                                    sm: { offset: 10, span: 11, },
                                    xs: { offset: 0, span: 24, },
                                }}
                            >
                                <Button 
                                    style={{width: "100%"}}
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoading}
                                >
                                    {t("Register application")}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                    }
                </Col>
        </ConfigProvider>
        )
}

export default TicketCreatePage