import React, { useEffect, useMemo, useState } from 'react';
import { Layout, Row, Col, Card, Tree, Select } from 'antd';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import './StatisticsPage.css'; 
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';

import { useGetStatisticsResponsibleSectorQuery, useGetStatisticsResponsibleStatusQuery, useGetStatisticsResponsibleTimeQuery } from '../../utils/api/ticketsApi';

const { Content } = Layout;
const { Option } = Select;

const StatisticsPage2 = () => {
    const { t, i18n } = useTranslation();
    const [selectedResponsibles, setSelectedResponsibles] = useState([]);
    const [filteredResponsibles, setFilteredResponsibles] = useState([]);
    
    const startYear = 2024; 
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const [selectedMonth, setSelectedMonth] = useState(`${currentYear}-${currentMonth}`);
    
    const [year, monthIndex] = selectedMonth.split('-'); 
    const date_start = moment().year(year).month(monthIndex).startOf('month').format('YYYY-MM-DD');
    const date_end = moment().year(year).month(monthIndex).endOf('month').format('YYYY-MM-DD');

    const { data: dataResponsibleSector, error: errorResponsibleSector, isLoading: isLoadingResponsibleSector } = useGetStatisticsResponsibleSectorQuery({ date_start, date_end });
    const { data: dataResponsibleStatus, error: errorResponsibleStatus, isLoading: isLoadingResponsibleStatus } = useGetStatisticsResponsibleStatusQuery({ date_start, date_end });
    const { data: dataResponsibleTime, error: errorResponsibleTime, isLoading: isLoadingResponsibleTime } = useGetStatisticsResponsibleTimeQuery({ date_start, date_end });

    const currentLocale = i18n.language;

    useEffect(() => {
        if (dataResponsibleSector?.data && dataResponsibleSector.data.length > 0) {
            const uniqueResponsibles = [...new Set(dataResponsibleSector.data.map(row => row.responsible.last_name))];
            setSelectedResponsibles(uniqueResponsibles);
            setFilteredResponsibles(uniqueResponsibles);
        }
    }, [dataResponsibleSector, currentLocale]);

    useEffect(() => {
        moment.locale(currentLocale);
    }, [currentLocale]);

    const handleResponsibleFilterChange = (value) => {
        setFilteredResponsibles(value);
    };
    
    const months = useMemo(() => {
        return currentLocale === 'ua'
            ? ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"]
            : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }, [currentLocale]);
    
    const monthTreeData = useMemo(() => {
        const years = [];
        for (let year = startYear; year <= currentYear; year++) {
            years.push({
                title: `${year}`,
                key: `${year}`,
                children: months
                    .slice(0, year === currentYear ? currentMonth + 1 : 12)
                    .map((month, index) => ({
                        title: month,
                        key: `${year}-${index}`,
                    })),
            });
        }
        return years;
    }, [months, currentMonth, currentYear]);

    const defaultExpandedKeys = useMemo(() => [`${currentYear}`], [currentYear]);

    const onMonthSelect = (selectedKeys) => {
        if (selectedKeys.length > 0) {
            setSelectedMonth(selectedKeys[0]);
        }
    };

    const getGradientColor = (value, min, max) => {
        const ratio = (value - min) / (max - min);
        const red = Math.round(200 * (1 - ratio));
        const green = Math.round(50 + (105 * ratio)); 
        return `rgba(${red}, ${green}, 0, 0.7)`;
    };

    // Table 1: Responsible - Sector
    const totalSumsResponsibleSector = useMemo(() => {
        if (!dataResponsibleSector?.data) return {};
    
        const sectors = [...new Set(dataResponsibleSector.data.map(row => row.sector[currentLocale === 'ua' ? 'name' : 'name_en']))];
    
        const totals = {};
        sectors.forEach(sector => {
            totals[sector] = dataResponsibleSector.data.reduce((sum, row) => sum + (row.sector[currentLocale === 'ua' ? 'name' : 'name_en'] === sector ? row.count : 0), 0);
        });
    
        totals[t('Total')] = Object.values(totals).reduce((sum, value) => sum + value, 0);
    
        return totals;
    }, [dataResponsibleSector, currentLocale, t]);

    const totalPercentagesResponsibleSector = useMemo(() => {
        if (!dataResponsibleSector?.data) return {};
        const totals = {};

        Object.keys(totalSumsResponsibleSector).forEach(key => {
            totals[key] = ((totalSumsResponsibleSector[key] / totalSumsResponsibleSector[t('Total')]) * 100).toFixed(1) + '%';
        });

        return totals;
    }, [totalSumsResponsibleSector, t]);

    const minMaxValuesResponsibleSector = useMemo(() => {
        if (!dataResponsibleSector?.data) return { min: 0, max: 100 };
    
        const values = Object.keys(totalPercentagesResponsibleSector)
            .map(key => parseFloat(totalPercentagesResponsibleSector[key]))
            .filter(value => value !== 100);
        const min = Math.min(...values);
        const max = Math.max(...values);
    
        return { min, max };
    }, [totalPercentagesResponsibleSector]);

    const columnsResponsibleSector = useMemo(() => {
        if (!dataResponsibleSector?.data || dataResponsibleSector.data.length === 0) return [];
    
        const sectors = [...new Set(dataResponsibleSector.data.map(row => row.sector[currentLocale === 'ua' ? 'name' : 'name_en']))];
        const dynamicColumns = sectors.map(sector => ({
            header: sector,
            accessorKey: sector,
            cell: info => info.getValue(),
        }));
    
        return [
            { 
                header: t('Responsible'), 
                accessorKey: 'responsible',
                cell: info => <strong>{info.getValue().last_name}</strong>,
                headerProps: { style: { width: '200px', backgroundColor: '#e0e0e0', fontWeight: 'bold', textAlign: 'center', color: '#333' } } 
            },
            ...dynamicColumns,
            { 
                header: t('Total'), 
                accessorKey: 'Total',
                cell: info => <strong>{info.getValue()}</strong>,
                headerProps: { style: { backgroundColor: '#e0e0e0', fontWeight: 'bold', textAlign: 'center', color: '#333' } }
            },
            { 
                header: '%', 
                accessorKey: '%',
                cell: info => {
                    const value = parseFloat(info.getValue());
                    if (value === 100) return null;
                    const color = getGradientColor(value, minMaxValuesResponsibleSector.min, minMaxValuesResponsibleSector.max);
                    return <span style={{ backgroundColor: color, padding: '5px', borderRadius: '3px', display: 'block', textAlign: 'center', color: 'white' }}><strong>{info.getValue()}%</strong></span>;
                },
                headerProps: { style: { backgroundColor: '#e0e0e0', fontWeight: 'bold', textAlign: 'center', color: '#333' } }
            },
        ];
    }, [dataResponsibleSector, minMaxValuesResponsibleSector, t, currentLocale]);

    const tableDataResponsibleSector = useMemo(() => {
        if (!dataResponsibleSector?.data) return [];
        const formattedData = filteredResponsibles.map(responsible => {
            const filteredData = dataResponsibleSector.data.filter(row => row.responsible.last_name === responsible);
            const rowData = { 'responsible': filteredData[0]?.responsible || {} };
            let sum = 0;
            filteredData.forEach(item => {
                rowData[item.sector[currentLocale === 'ua' ? 'name' : 'name_en']] = item.count;
                sum += item.count;
            });
            rowData[t('Total')] = sum;
            rowData['%'] = ((sum / totalSumsResponsibleSector[t('Total')]) * 100).toFixed(1);
            return rowData;
        });
    
        return formattedData;
    }, [dataResponsibleSector, filteredResponsibles, totalSumsResponsibleSector, currentLocale, t]);

    const tableResponsibleSector = useReactTable({
        data: tableDataResponsibleSector,
        columns: columnsResponsibleSector,
        getCoreRowModel: getCoreRowModel(),
    });

    // Table 2: Responsible - Status
    const totalSumsResponsibleStatus = useMemo(() => {
        if (!dataResponsibleStatus?.data) return {};
    
        const statuses = [...new Set(dataResponsibleStatus.data.map(row => row.status[currentLocale === 'ua' ? 'name' : 'name_en']))];
    
        const totals = {};
        statuses.forEach(status => {
            totals[status] = dataResponsibleStatus.data.reduce((sum, row) => sum + (row.status[currentLocale === 'ua' ? 'name' : 'name_en'] === status ? row.count : 0), 0);
        });
    
        totals[t('Total')] = Object.values(totals).reduce((sum, value) => sum + value, 0);
    
        return totals;
    }, [dataResponsibleStatus, currentLocale, t]);

    const totalPercentagesResponsibleStatus = useMemo(() => {
        if (!dataResponsibleStatus?.data) return {};
        const totals = {};

        Object.keys(totalSumsResponsibleStatus).forEach(key => {
            totals[key] = ((totalSumsResponsibleStatus[key] / totalSumsResponsibleStatus[t('Total')]) * 100).toFixed(1) + '%';
        });

        return totals;
    }, [totalSumsResponsibleStatus, t]);

    const minMaxValuesResponsibleStatus = useMemo(() => {
        if (!dataResponsibleStatus?.data) return { min: 0, max: 100 };
    
        const values = Object.keys(totalPercentagesResponsibleStatus)
            .map(key => parseFloat(totalPercentagesResponsibleStatus[key]))
            .filter(value => value !== 100);
        const min = Math.min(...values);
        const max = Math.max(...values);
    
        return { min, max };
    }, [totalPercentagesResponsibleStatus]);

    const columnsResponsibleStatus = useMemo(() => {
        if (!dataResponsibleStatus?.data || dataResponsibleStatus.data.length === 0) return [];
    
        const statuses = [...new Set(dataResponsibleStatus.data.map(row => row.status[currentLocale === 'ua' ? 'name' : 'name_en']))];
        const dynamicColumns = statuses.map(status => ({
            header: status,
            accessorKey: status,
            cell: info => info.getValue(),
        }));
    
        return [
            { 
                header: t('Responsible'), 
                accessorKey: 'responsible',
                cell: info => <strong>{info.getValue().last_name}</strong>,
                headerProps: { style: { width: '200px', backgroundColor: '#e0e0e0', fontWeight: 'bold', textAlign: 'center', color: '#333' } } 
            },
            ...dynamicColumns,
            { 
                header: t('Total'), 
                accessorKey: 'Total',
                cell: info => <strong>{info.getValue()}</strong>,
                headerProps: { style: { backgroundColor: '#e0e0e0', fontWeight: 'bold', textAlign: 'center', color: '#333' } }
            },
            { 
                header: '%', 
                accessorKey: '%',
                cell: info => {
                    const value = parseFloat(info.getValue());
                    if (value === 100) return null;
                    const color = getGradientColor(value, minMaxValuesResponsibleStatus.min, minMaxValuesResponsibleStatus.max);
                    return <span style={{ backgroundColor: color, padding: '5px', borderRadius: '3px', display: 'block', textAlign: 'center', color: 'white' }}><strong>{info.getValue()}%</strong></span>;
                },
                headerProps: { style: { backgroundColor: '#e0e0e0', fontWeight: 'bold', textAlign: 'center', color: '#333' } }
            },
        ];
    }, [dataResponsibleStatus, minMaxValuesResponsibleStatus, t, currentLocale]);

    const tableDataResponsibleStatus = useMemo(() => {
        if (!dataResponsibleStatus?.data) return [];
        const formattedData = filteredResponsibles.map(responsible => {
            const filteredData = dataResponsibleStatus.data.filter(row => row.responsible.last_name === responsible);
            const rowData = { 'responsible': filteredData[0]?.responsible || {} };
            let sum = 0;
            filteredData.forEach(item => {
                rowData[item.status[currentLocale === 'ua' ? 'name' : 'name_en']] = item.count;
                sum += item.count;
            });
            rowData[t('Total')] = sum;
            rowData['%'] = ((sum / totalSumsResponsibleStatus[t('Total')]) * 100).toFixed(1);
            return rowData;
        });

        return formattedData;
    }, [dataResponsibleStatus, filteredResponsibles, totalSumsResponsibleStatus, currentLocale, t]);

    const tableResponsibleStatus = useReactTable({
        data: tableDataResponsibleStatus,
        columns: columnsResponsibleStatus,
        getCoreRowModel: getCoreRowModel(),
    });

    // Table 3: Responsible - Time
    const columnsResponsibleTime = useMemo(() => {
        return [
            { 
                header: t('Responsible'), 
                accessorKey: 'responsible',
                cell: info => <strong>{info.getValue().last_name}</strong>,
                headerProps: { style: { width: '200px', backgroundColor: '#e0e0e0', fontWeight: 'bold', textAlign: 'center', color: '#333' } } 
            },
            { 
                header: t('Resolving time'), 
                accessorKey: 'time',
                cell: info => info.getValue(),
                headerProps: { style: { backgroundColor: '#e0e0e0', fontWeight: 'bold', textAlign: 'center', color: '#333' } }
            },
        ];
    }, [t]);

    const tableDataResponsibleTime = useMemo(() => {
        if (!dataResponsibleTime?.data) return [];
    
        // Filter the data to only include responsibles that match the selected filter
        const filteredData = filteredResponsibles.map(responsible => {
            const rowData = dataResponsibleTime.data.find(row => row.responsible.last_name === responsible);
            return rowData ? { responsible: rowData.responsible, time: rowData.time } : null;
        }).filter(row => row !== null); // Remove any null values
    
        return filteredData;
    }, [dataResponsibleTime, filteredResponsibles]);

    const tableResponsibleTime = useReactTable({
        data: tableDataResponsibleTime,
        columns: columnsResponsibleTime,
        getCoreRowModel: getCoreRowModel(),
    });

    if (isLoadingResponsibleSector || isLoadingResponsibleStatus || isLoadingResponsibleTime) return <div>{t('loading')}</div>

    if (errorResponsibleSector || errorResponsibleStatus || errorResponsibleTime) return <div>{t('error')}: {errorResponsibleSector?.message || errorResponsibleStatus?.message || errorResponsibleTime?.message}</div>

    return (
        <Layout>
            <Content style={{ padding: '2px' }}>
                <Row gutter={16}>
                    <Col span={4}>
                        <Card>
                            <div>
                                <Tree
                                    defaultExpandAll={false}
                                    defaultExpandedKeys={defaultExpandedKeys}
                                    selectedKeys={[selectedMonth]}
                                    onSelect={onMonthSelect}
                                    treeData={monthTreeData}
                                />
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%', marginTop: '10px' }}
                                    placeholder={t('Select Responsible')}
                                    value={filteredResponsibles}
                                    onChange={handleResponsibleFilterChange}
                                    allowClear
                                >
                                    {selectedResponsibles.map(responsible => (
                                        <Option key={responsible} value={responsible}>
                                            {responsible}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Card>
                    </Col>
                    <Col span={20}>
                        <table className="custom-table">
                            <thead>
                                {tableResponsibleSector.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {tableResponsibleSector.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                <tr>
                                    <td><strong>{t('Total')}</strong></td>
                                    {Object.keys(totalSumsResponsibleSector).map(key => (
                                        <td key={key}><strong>{totalSumsResponsibleSector[key]}</strong></td>
                                    ))}
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>{t('%')}</strong></td>
                                    {Object.keys(totalPercentagesResponsibleSector).map(key => {
                                        const value = parseFloat(totalPercentagesResponsibleSector[key]);
                                        if (value === 100) return <td key={key}></td>;
                                        const color = getGradientColor(value, minMaxValuesResponsibleSector.min, minMaxValuesResponsibleSector.max);
                                        return (
                                            <td key={key} style={{ backgroundColor: color, textAlign: 'center', color: 'white' }}>
                                                <strong>{totalPercentagesResponsibleSector[key]}</strong>
                                            </td>
                                        );
                                    })}
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="custom-table" style={{ marginTop: '20px' }}>
                            <thead>
                                {tableResponsibleStatus.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                            {tableResponsibleStatus.getRowModel().rows.map(row => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            <tr>
                                <td><strong>{t('Total')}</strong></td>
                                {Object.keys(totalSumsResponsibleStatus).map(key => (
                                    <td key={key}><strong>{totalSumsResponsibleStatus[key]}</strong></td>
                                ))}
                                <td></td>
                            </tr>
                            <tr>
                                <td><strong>{t('%')}</strong></td>
                                {Object.keys(totalPercentagesResponsibleStatus).map(key => {
                                    const value = parseFloat(totalPercentagesResponsibleStatus[key]);
                                    if (value === 100) return <td key={key}></td>;
                                    const color = getGradientColor(value, minMaxValuesResponsibleStatus.min, minMaxValuesResponsibleStatus.max);
                                    return (
                                        <td key={key} style={{ backgroundColor: color, textAlign: 'center', color: 'white' }}>
                                            <strong>{totalPercentagesResponsibleStatus[key]}</strong>
                                        </td>
                                    );
                                })}
                                <td></td>
                            </tr>
                        </tbody>
                        </table>
                        <table className="custom-table" style={{ marginTop: '20px' }}>
                            <thead>
                                {tableResponsibleTime.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {tableResponsibleTime.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Col>
                </Row>
        </Content>
    </Layout>
    );
};

export default StatisticsPage2;