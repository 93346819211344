import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Row, Typography, Layout, Flex, Tooltip, Radio } from 'antd'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { ConfigProvider } from 'antd';
import { GB, UA } from 'country-flag-icons/react/3x2'
import enUS from 'antd/locale/en_US';
import ukUA from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';

const { Title, Text, Link } = Typography
const { Header, Content, Footer } = Layout

const ClientStartPage = () => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState('ua');
    const [renderKey, setRenderKey] = useState(Date.now());
    const navigate = useNavigate();

    useEffect(() => {
        setLanguage("ua");
        i18n.changeLanguage("ua");
    }, []);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
        setRenderKey(Date.now());
      };


  return (
    <ConfigProvider 
        locale={language === 'en' ? enUS : ukUA} 
        localeAntd={language === 'en' ? enUS : ukUA}
        theme={{
            token: {
            colorPrimary: '#00acee', // Set your primary color here
            },
        }}
        >
        <Flex align="center" justify="center" style={{paddingTop:"12px", paddingBottom:"8px"}}>
            <Col style={{display:"flex", justifyContent:"center"}}>
                <img style={{maxWidth:"300px", maxHeight:"300px"}} alt="unicef" 
                    src={`${process.env.PUBLIC_URL}/unicef_${language}.png`}
                    />
            </Col>
        </Flex>
            <Flex justify='center' align='center' style={{paddingBottom:"16px"}}>
                <Radio.Group buttonStyle="solid" onChange={(v)=>{changeLanguage(v.target.value)}} defaultValue={language} optionType="button">
                    <Tooltip className="main-layout-language-radio" title={t('English')}><Radio.Button  value="en"><GB className="flag-icon"/></Radio.Button></Tooltip>
                    <Tooltip className="main-layout-language-radio" title={t('Українська')}><Radio.Button value="ua"><UA className="flag-icon"/></Radio.Button></Tooltip>
                </Radio.Group>
            </Flex>
            <Flex gap="large" align="center" justify="center" style={{padding: "24px", height:"70vh"}}>
                <Row style={{minHeight: "60%", maxWidth: "350px"}} justify="center">
                    <Row 
                        style={{ 
                            minHeight: 100,
                            maxHeight: 100,
                            minWidth: "100%",
                            padding: 12,
                            alignContent: "center",
                        }}            
                    >
                        <Button
                            type="primary"
                            style={{ 
                                minHeight: 70, 
                                minWidth: "100%",
                                // padding: 12, 
                                borderRadius: '20px'
                            }}
                            onClick={() => navigate("/create_ticket")}
                        >
                            <Title level={5} style={{margin:0, color:"white"}}>{t("Application registration")}</Title>
                        </Button>
                    </Row>
                    <Row
                        style={{ 
                            minHeight: 100,
                            maxHeight: 100,
                            minWidth: "100%",
                            padding: 12,
                            alignContent: "center",
                        }}    
                    >
                    <Button
                        type="primary"
                        style={{ 
                            minHeight: 70, 
                            minWidth: "100%",
                            // padding: 12, 
                            borderRadius: '20px'
                        }}
                        onClick={() => navigate("/check_ticket_status")}
                    >
                        <Title level={5} style={{margin:0, color:"white"}}>{t("Check application status")}</Title>
                    </Button>
                    </Row>
                </Row>
            </Flex>
            {/* </Row> */}
    </ConfigProvider>
  )
}

export default ClientStartPage