import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { List, Flex, Row, Col, Checkbox, Select, Radio, Table, Typography, Button, Input, Space, Modal, Form } from 'antd';
import { SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import { 
    useAddUserMutation,
    useGetUsersQuery,
    useGetUserSectorQuery,
    useGetUsersRegionsQuery,
    useSetUserRegionMutation,
    useAddUserSectorMutation,
    useRemoveUserSectorMutation,
    useSetUserSubscriptionMutation,
} from '../../utils/api/ticketsApi';
import './UsersPage.css';
import { MO } from 'country-flag-icons/react/3x2';

const { Title, Text, Link } = Typography;

const UsersPage = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const { data: usersData } = useGetUsersQuery();
    const { data: sectorsData } = useGetUserSectorQuery();
    const { data: regionsData } = useGetUsersRegionsQuery();

    const [filterText, setFilterText] = useState('');
    const [filteredUsers, setFilteredUsers] = useState(usersData);

    const [isModaiVisible, setIsModaiVisible] = useState(false);

    const [addUser, {result: addUserResult, isLoading: addUserIsLoading, isFetching: addUserIsFetching, isSuccess: addUserIsSuccess}] = useAddUserMutation();
    const [setUserRegion] = useSetUserRegionMutation();
    const [addUserSector] = useAddUserSectorMutation();
    const [removeUserSector] = useRemoveUserSectorMutation();
    const [setUserSubscription, {result: setUserSubscriptionResult, isLoading: setUserSubscriptionLoading, isFetching: setUserSubscriptionFetching, isSuccess: setUserSubscriptionIsSuccess}] = useSetUserSubscriptionMutation();

    useEffect(() => {
        if (usersData) {
            setFilteredUsers(usersData);
        }
    }, [usersData]);

    const handleSubscription = (username, subscription) => {
        setUserSubscription({username, subscription});
        const tempFilteredUsers = filteredUsers.map(user => {
            if (user.username === username) {
                return {...user, subscription};
            }
            return user;
        });
        setFilteredUsers(tempFilteredUsers);
    }

    useEffect(() => {
        if (addUserIsSuccess) {
            setIsModaiVisible(false);
            form.resetFields();
        }
    }, [addUserIsSuccess]);

    useEffect(() => {
        if (filterText) {
            setFilteredUsers([...usersData].filter(user => user.username.toLowerCase().includes(filterText.toLowerCase()) 
            || user.email.toLowerCase().includes(filterText.toLowerCase()) 
            || user.first_name.toLowerCase().includes(filterText.toLowerCase()) 
            || user.last_name.toLowerCase().includes(filterText.toLowerCase())));
            // usersData.filter(user => user.username.toLowerCase().includes(filterText.toLowerCase()))
        } else {
            setFilteredUsers(usersData);
        }
    }, [filterText]);

    const columns = [
        {
            title: t('Username'),
            dataIndex: 'username',
            key: 'username',
            width: '180px',
            render: (text, record) => (<Link>{record.username}</Link>),
        },
        {
            title: t('First name'),
            dataIndex: 'first_name',
            key: 'first_name',
            width: '180px',
            render: (text, record) => (<Text>{record.first_name}</Text>),
        },
        {
            title: t('Last name'),
            dataIndex: 'last_name',
            key: 'last_name',
            width: '180px',
            render: (text, record) => (<Text>{record.last_name}</Text>),
        },
        {
            title: t('Email'),
            dataIndex: 'email',
            key: 'email',
            width: '230px',
            render: (text, record) => (<Link>{record.email}</Link>),
        },
        {
            title: t('Region'),
            dataIndex: 'region',
            key: 'region',
            render: (text, record) => (
                    <Select
                        mode="single"
                        allowClear={true}
                        showSearch={true}
                        autoClearSearchValue={true}
                        style={{minWidth: '250px',}}
                        placeholder={t('Region')}
                        defaultValue={record?.access?.allowed_region}
                        onSelect={(e)=>setUserRegion({username: record.username, region: e})}
                        options={regionsData}
                    />
            ),
        },
        {
            title: t('Sector'),
            dataIndex: 'sector',
            key: 'sector',
            render: (text, record) => (
                    <Select
                        mode="multiple"
                        allowClear={false}
                        maxTagCount={1}
                        style={{minWidth: '350px'}}
                        placeholder={t('Sector')}
                        defaultValue={record?.access?.allowed_sectors?.map(sector => sector.name)}
                        onDeselect={(e)=>removeUserSector({username: record.username, sector: e})}
                        onSelect={(e)=>addUserSector({username: record.username, sector: e})}
                        options={sectorsData}
                        optionRender={(item) => {return (<Text>{t(item?.label?.trim())}</Text>)}}
                        labelRender={(item) => {return (<Text>{t(item?.label?.trim())}</Text>)}}
                    />
            ),
        },
        {
            title: t('Subscription'),
            dataIndex: 'subscription',
            key: 'subscription',
            width: '80px',
            render: (text, record) => (
                <Flex justify='center' align='center'>
                    <Checkbox
                        checked={record.subscription}
                        // disabled={setUserSubscriptionFetching}
                        onChange={(e)=> {
                            handleSubscription(record.username, e?.target?.checked);
                        }}
                    />
                </Flex>
            ),
        }
    ];

    const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 8,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 16,
          },
        },
      };


    return (
        <Row className="users-page-content">
            <Row style={{padding:"12px"}}>
                <Space direction="horizontal" size="large">
                    <Input
                        placeholder={t('Search user')}
                        prefix={<SearchOutlined />}
                        onChange={(e)=>setFilterText(e.target.value)}
                    />
                    <Button
                        type="primary"
                        icon={<UserAddOutlined />}
                        onClick={()=>setIsModaiVisible(true)}
                    >
                        {t('Add user')}
                    </Button>
                </Space>
            </Row>
            <Row>
                <Table
                    size="small"
                    rowKey="username"
                    dataSource={filteredUsers}
                    columns={columns}
                    pagination={false}
                />
            </Row>
        <Modal
            title="New user registration"
            closable={false} 
            open={isModaiVisible} 
            onCancel={()=>setIsModaiVisible(false)} 
            footer={null} 
        >
            <Form 
                {...formItemLayout}
                name="register"
                form={form}
                onFinish={(values)=>addUser(values)}
            >
                <Form.Item
                    label={t("First name")}
                    name="first_name"
                    rules={[{ required: true, message: t('Please input firts name.') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("Last name")}
                    name="last_name"
                    rules={[{ required: true, message: t('Please input last name.') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label={t("Email")}
                    rules={[
                    {
                        type: 'email',
                        message: t('The input is not valid Email.'),
                    },
                    {
                        required: true,
                        message: t('Please input Email.'),
                    },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={t("Password")}
                    rules={[
                    {
                        required: true,
                        message: t('Please input password.'),
                    },
                    { min: 8, message: t('Password must be at least 8 characters long.') },
                    { max: 20, message: t('Password must be at most 20 characters long.') },
                    { pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, message: t('Password must contain at least one letter and one number.') },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label={t("Confirm password")}
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                    {
                        required: true,
                        message: t('Please confirm your password.'),
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error(t('The new password that you entered do not match.')));
                        },
                    }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Button 
                        type="primary" 
                        htmlType="submit"
                        loading={addUserIsLoading}
                    >
                        {t("Register user")}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
        </Row>
    )
}

export default UsersPage