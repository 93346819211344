import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { Input, Radio, Button, Form, Typography, Card, Result, Flex, Layout, Tooltip, Grid, Col, Row, Spin } from 'antd';
import { SmileOutlined, MehOutlined, FrownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSubmitFeedbackMutation, useGetTicketFeedbackQuery } from '../../utils/api/ticketsApi';
import { ConfigProvider } from 'antd';
import { GB, IN, UA } from 'country-flag-icons/react/3x2';
import enUS from 'antd/locale/en_US';
import ukUA from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const smiles = ["😭", "😢", "😥", "😓", "😞", "😕", "😐", "🙂", "😊", "😁", "😄"];

const FeedbackPage = () => {
  const params = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const [renderKey, setRenderKey] = useState(Date.now());
  const [language, setLanguage] = useState('ua');
  const [form] = Form.useForm();
  const [feedbackResult, setFeedbackResult] = useState(null);

  const {data, error, isLoading:isLoadingTicketFeedback, isFetching:isFetchingTicketFeedback, isSuccess:isSuccessTicketFeedback, isError:isErrorTicketFeedback} = useGetTicketFeedbackQuery({uuid:params?.uuid});

  const [createFeedback, { isLoading, isSuccess }] = useSubmitFeedbackMutation();

  useEffect(() => {
    if (!params?.uuid) {
      navigate('/404');
    }
  }, [params?.uuid, navigate]);

  useEffect(() => {
    setLanguage("ua");
    i18n.changeLanguage("ua");
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    setRenderKey(Date.now());
  };

  const onFinish = async (values) => {
    try {
      values['uuid'] = params?.uuid;
      const response = await createFeedback(values).unwrap();
      setFeedbackResult(response?.detail);
      form.resetFields();
    } catch (error) {
      console.log(error);
      setFeedbackResult(error?.data?.detail);
      form.resetFields();
    }
  };

  const formItemLayout = {
    labelAlign: 'right',
    labelCol: { xl: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } },
    wrapperCol: { xl: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } },
  };

  const headerLayout = { xl: { span: 5 }, sm: { span: 24 }, xs: { span: 24 } };
  const headerLayout2 = { xl: { span: 14 }, sm: { span: 24 }, xs: { span: 24 } };
  const headerLayout3 = { xl: { span: 5 }, sm: { span: 24 }, xs: { span: 24 } };

  if(isLoadingTicketFeedback){
    return (
        <Col {...{ xl: { span: 20, pull: 2, push: 2 } }} style={{alignContent:"center", justifyContent:"center"}}>
            <Spin size='large' />
        </Col>
    )
  }

  if(!isSuccessTicketFeedback && isErrorTicketFeedback && error?.data?.detail==="Ticket with given uuid not found"){
    return (
        <Col {...{ xl: { span: 20, pull: 2, push: 2 } }}>
            <Result status="error" title={t("There is no such appeal.")} />
        </Col>
    )
  };

  if(!isSuccessTicketFeedback && isErrorTicketFeedback && error?.data?.detail==="You have already left a feedback"){
    return (
        <Col {...{ xl: { span: 20, pull: 2, push: 2 } }}>
            <Result status="error" title={t("You have already left feedback.")} />
        </Col>
    )
  }; 

  return (
    <ConfigProvider 
      locale={language === 'en' ? enUS : ukUA}
      localeAntd={language === 'en' ? enUS : ukUA}
      theme={{
        token: {
          colorPrimary: '#00acee', // Set your primary color here
        },
      }}
      >
      <Col {...{ xl: { span: 20, pull: 2, push: 2 } }}>
        {isSuccess && isSuccessTicketFeedback && feedbackResult === "ok" ? (
            <Result status="success" title={t("Thank you for your feedback!")} />
        ) : (
          <Card
            title={
              <Row>
                {!screens.xl && (
                  <Col xs={24} sm={24} xl={0} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                    <img style={{ maxWidth: "150px" }} alt="unicef" src={`${process.env.PUBLIC_URL}/unicef_${language}.png`} />
                    <img style={{ maxWidth: "150px" }} alt="hotline" src={`${process.env.PUBLIC_URL}/hotline_${language}.png`} />
                  </Col>
                )}
                {screens.xl && (
                  <Col {...headerLayout} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img style={{ maxWidth: "200px" }} alt="unicef" src={`${process.env.PUBLIC_URL}/unicef_${language}.png`} />
                    </Col>
                )}
                <Col {...headerLayout2} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  <Row>
                    <Col span={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Title style={{ wordBreak: 'break-word', whiteSpace: 'normal', textAlign: 'center' }} strong={true} level={2}>{t("Welcome! You have submitted a request to our UNICEF Ukraine information support hotline.")}</Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Col span={14} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Title style={{ wordBreak: 'break-word', whiteSpace: 'normal', textAlign: 'center' }} italic strong level={5} type="secondary">
                          {t("We would appreciate it if you could answer a few questions:")}
                        </Title>
                      </Col>
                    </Col>
                  </Row>
                </Col>
                {screens.xl && (
                  <Col {...headerLayout3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img style={{ maxWidth: "200px" }} alt="hotline" src={`${process.env.PUBLIC_URL}/hotline_${language}.png`} />
                  </Col>
                )}
              </Row>
            }
            style={{ width: "100%", padding: 4, marginTop: 4, margin: "auto" }}
          >
            <Col {...{
              xl: { offset: 0, span: 24 },
              sm: { offset: 10, span: 12 },
              xs: { offset: 0, span: 24 },
            }} style={{ paddingTop: "12px" }}>
              <Flex justify='center' align='center' style={{ paddingBottom: "16px" }}>
                <Radio.Group buttonStyle="solid" onChange={(v) => { changeLanguage(v.target.value) }} defaultValue={language} optionType="button">
                  <Tooltip title={t('English')}><Radio.Button value="en"><GB className="flag-icon" /></Radio.Button></Tooltip>
                  <Tooltip title={t('Українська')}><Radio.Button value="ua"><UA className="flag-icon" /></Radio.Button></Tooltip>
                </Radio.Group>
              </Flex>
            </Col>
            <Col {...{
              xl: { offset: 0, span: 24 },
              sm: { offset: 0, span: 24 },
              xs: { offset: 0, span: 24 },
            }} style={{ padding: "12px" }}>
            
            <Form
              name="feedback"
              {...formItemLayout}
              form={form}
              onFinish={onFinish}
            >
                <Row style={{paddingBottom:"12px"}}>
                    <Text>{t("1. How satisfied are you with the solution or response you received from our team through the hotline?")}</Text>
                </Row>
                <Row>
              <Form.Item
                style={{padding:"4px"}}
                name="sat"
                rules={[{ required: true, message: t("Please make your choice.") }]}
              >
                <Radio.Group optionType="button" buttonStyle="solid">
                  <Radio value={1}>{t("Very dissatisfied")}</Radio>
                  <Radio value={2}>{t("Dissatisfied")}</Radio>
                  <Radio value={3}>{t("Neutral")}</Radio>
                  <Radio value={4}>{t("Satisfied")}</Radio>
                  <Radio value={5}>{t("Very satisfied")}</Radio>
                </Radio.Group>
              </Form.Item>
              </Row>
              <Row style={{paddingBottom:"12px"}}>
                <Text>{t("2. How likely are you to recommend our hotline for feedback to a friend or colleague? Please rate on a scale from 0 to 10, where “0” means “Not at all likely” and “10” means “Definitely will recommend”.")}</Text>
                </Row>
                <Row>
              <Form.Item
              style={{padding:"4px"}}
                name="rec"
                rules={[{ required: true, message: t("Please make your choice.") }]}
              >
                
                
                <Radio.Group optionType="button" buttonStyle="solid">
                  {[...Array(11)].map((_, index) => (
                    <Radio key={index} value={index}>
                      {index} {smiles[index]}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              </Row>
              <Form.Item
                wrapperCol={{
                  xl: { offset: 0, span: 24 },
                  sm: { offset: 0, span: 24 },
                  xs: { offset: 0, span: 24 },
                }}
              >
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {t("Save")}
                </Button>
              </Form.Item>
            </Form>
            </Col>
          </Card>
        )}
      </Col>
    </ConfigProvider>
  );
};

export default FeedbackPage;