import { GoogleReCaptchaProvider, useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import React, { useState, useEffect } from 'react'
import { Input, Row, Radio, Button, Form, Typography, Card, Result, Flex, Layout, Tooltip, Image, Col, Tag } from 'antd'
import { SyncOutlined, IssuesCloseOutlined, ExclamationCircleOutlined, HistoryOutlined, CommentOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import { useGetTypesQuery, useGetCategoriesQuery, useGetRegionsQuery, useGetDistrictsQuery, useGetOtgsQuery, useGetSectorsQuery } from '../../utils/api/ticketsApi'
import { useCheckStatusMutation } from '../../utils/api/ticketsApi'
import { ConfigProvider } from 'antd';
import { GB, UA } from 'country-flag-icons/react/3x2'
import enUS from 'antd/locale/en_US';
import ukUA from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';


const { Header, Content, Footer } = Layout;
const { Title, Text, Link } = Typography

const statusColors = [
    {color: "red", icon: <IssuesCloseOutlined />, nextStatuses: [1]}, //new
    {color: "blue", icon: <SyncOutlined />, nextStatuses: [2, 3, 4]}, //in progress
    {color: "yellow", icon: <SyncOutlined />, nextStatuses: [0, 4]}, //on hold
    {color: "grey", icon: <SyncOutlined />, nextStatuses: [0, 4 ]}, //rejected
    {color: "green", icon: <ExclamationCircleOutlined />, nextStatuses: []} //closed
  ];


const CheckStatusPage = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [renderKey, setRenderKey] = useState(Date.now());
    const [language, setLanguage] = useState('ua');
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [form] = Form.useForm();
    const [ticketId, setTicketId] = useState(null);
    const [phone, setPhone] = useState(null);
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);

    const [checkStatus, {result, isLoading, isSuccess, isError}] = useCheckStatusMutation();

    const onFinish = async (values) => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available.');
            return;
          }
        const recaptcha = await executeRecaptcha("check_status");
        const vals = {...values, recaptcha}
        await checkStatus(vals)
        .then((res) => {
            setError(res?.error?.data?.detail)
            setStatus(res?.data?.status)
        })
        .catch((err) => {
            setError(err)
            console.log(err)
        })
    }

    useEffect(() => {
        setLanguage("ua");
        i18n.changeLanguage("ua");
    }, []);


      const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
        setRenderKey(Date.now());
      };

    const formItemLayout = {
        labelAlign: 'right',
        labelCol: { lg: {span: 6}, sm: {span: 10}, xs: {span: 24} },
        wrapperCol: { lg: {span: 16}, sm: {span: 12}, xs: {span: 24} },
      };

    return (
        <ConfigProvider 
            locale={language === 'en' ? enUS : ukUA}
            localeAntd={language === 'en' ? enUS : ukUA}
            theme={{
                token: {
                colorPrimary: '#00acee', // Set your primary color here
                },
            }}
            >
            <Layout
                style={{ minHeight: "100vh" }}
            >
                <Content>
                <Row justify="center">
                    <Col 
                        xs={{ span: 22 }} // Full width on small screens
                        sm={{ span: 20 }} // Smaller width on medium screens
                        lg={{ span: 12 }} // Set to half width on large screens
                    >
                    {error ?
                    <Result
                        status="error"
                        title={
                                <>
                                <Title level={4}>
                                    {t(error)}
                                </Title>
                                </>}

                        extra={[
                            <Title level={2}>{ticketId}</Title>,
                            <Button type="primary" key="again" onClick={()=>navigate("/create_ticket")}>{t("Register another")}</Button>,
                            <Button type="primary" key="check" onClick={()=>{
                                setError(null)
                                navigate("/check_ticket_status")
                            }}>{t("Check application status")}</Button>,
                        ]}
                    />
                  :  
                    status && isSuccess ?    
                    <Result
                        status="success"
                        title={
                                <>
                                <Title level={4}>
                                    {t("Application status:")}
                                </Title>
                                <Tag 
                                    type="primary" 
                                    style={{width: '280px', height:"64px", textAlign: "center", textJustify: "center", fontSize: "24px", lineHeight: "64px"}}
                                    color={statusColors[status.id-1]?.color} 
                                >
                                    {t(status?.name)}
                                </Tag>
                                </>}

                        extra={[
                            <Title level={2}>{ticketId}</Title>,
                            <Button type="primary" key="again" onClick={()=>navigate("/create_ticket")}>{t("Register another")}</Button>,
                            <Button type="primary" key="check" onClick={()=>{
                                setStatus(null)
                                navigate("/check_ticket_status")
                            }}>{t("Check application status")}</Button>,
                        ]}
                    />
                    :
                    <Card
                        title={<Flex justify='center'><Title level={4}>{t("Check application status")}</Title></Flex>}
                        style={{maxWidth: "100%", margin: "auto", marginTop: "20px"}}
                        cover={
                            <Flex align="center" justify="space-between" style={{paddingTop:"12px"}}>
                                <Col style={{display:"flex", justifyContent:"center"}}>
                                    <img style={{maxWidth:"300px"}} alt="unicef" src={`${process.env.PUBLIC_URL}/unicef_${language}.png`}/>
                                </Col>
                            </Flex>
                            }
                    >
                        <Flex justify='center' align='center' style={{paddingBottom:"16px"}}>
                            <Radio.Group buttonStyle="solid" onChange={(v)=>{changeLanguage(v.target.value)}} defaultValue={language} optionType="button">
                                <Tooltip className="main-layout-language-radio" title={t('English')}><Radio.Button  value="en"><GB className="flag-icon"/></Radio.Button></Tooltip>
                                <Tooltip className="main-layout-language-radio" title={t('Українська')}><Radio.Button value="ua"><UA className="flag-icon"/></Radio.Button></Tooltip>
                            </Radio.Group>
                        </Flex>
                        <Form 
                            {...formItemLayout}
                            form={form} 
                            key={renderKey} 
                            onFinish={onFinish}
                            >
                            <Form.Item
                                label={t("Application ID")}
                                name="uid"
                                rules={[
                                    { required: true, message: t("Please input application ID.") },
                                    { pattern: /^[A-Za-z]{2}[0-9]{6}$/, message: t("Format must be two latin letters and six digits (NR123456).") }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                label={t("Phone")}
                                placeholder={"+380xxxxxxxxx"}
                                rules={[
                                    { required: true, message: t("Please input your phone number.") },
                                    { pattern: /^\+380\d{9}$/, message: t("Please input valid phone number in format +380xxxxxxxxx") }
                                ]}
                            >
                                <Input 
                                    type="phone"
                                />
                            </Form.Item>
                            <Form.Item
                                
                                wrapperCol={{
                                    lg: { offset: 6, span: 16, },
                                    sm: { offset: 10, span: 12, },
                                    xs: { offset: 0, span: 24, },
                                }}
                            >
                                <Button style={{textAlign: "center", width: "100%"}} type="primary" htmlType="submit">{t("Check status")}</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                    }
                    </Col>
                </Row>
                </Content>
            </Layout>
        </ConfigProvider>
    )
}

export default CheckStatusPage