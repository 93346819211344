import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import PermissionRoute from './PermissionRoute';
import Page404 from './Page404';
import { 
    LoginPage, 
    TicketsPage, 
    TicketPage, 
    HomePage, 
    AudioRecordsPage, 
    UsersPage, 
    TicketCreatePage, 
    CheckStatusPage, 
    ClientStartPage, 
    StatisticsPage,
    FeedbackPage,
} from '../';


const MainRouter = () => {
    return (
        <>
            <Routes>
              <Route exact path="/login" element={<LoginPage/>}/>
              <Route exact path="/feedback/:uuid" element={<FeedbackPage />} />
              <Route exact path="/tickets" element={<PermissionRoute requiredPermission="view_ticket"><TicketsPage /></PermissionRoute>} />
              <Route exact path="/client_start_page" element={<ClientStartPage />} />
              <Route exact path="/start" element={<ClientStartPage />} />
              <Route exact path="/create_ticket" element={<TicketCreatePage />} />
              <Route exact path="/check_ticket_status" element={<CheckStatusPage />} />
              <Route exact path="/tickets/:ticketId" element={<PermissionRoute requiredPermission="view_ticket"><TicketPage /></PermissionRoute>} />
              <Route exact path="/users" element={<PermissionRoute requiredPermission="manage_users"><UsersPage /></PermissionRoute>} />
              <Route exact path="/audiorecords" element={<PermissionRoute requiredPermission="view_audiorecords"><AudioRecordsPage /></PermissionRoute>} />
              <Route exact path="/statistics" element={<PermissionRoute requiredPermission="view_ticket"><StatisticsPage /></PermissionRoute>} />
              {/* <Route exact path="/audiorecords" element={<PermissionRoute requiredPermission="authentication.can_access_records"><AudioRecordsPage /></PermissionRoute>} /> */}
              <Route exact path="/" element={<PrivateRoute><HomePage /></PrivateRoute>} />
              <Route exact path="*" element={<Page404/>} /> 
            </Routes>
        </>
    )
}

export default MainRouter
